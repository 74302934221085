import React, { useEffect, useState, useRef} from "react";
import emailjs from '@emailjs/browser'
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
// import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
// import homePageAPI from "../services/homePageAPI";
import PhoneInput from "react-phone-number-input";
import validator from "validator"; // Import validator library for additional validation

export const Home = () => {
  // const history = useHistory();
  const navigate = useNavigate();
  const form = useRef();
  const [apiContactUsData, setApiContactUsData] = useState({});
  const [apiHomeBannerData, setApiHomeBannerData] = useState([]);
  const [apiTrustedByTheBest, setApiTrustedByTheBest] = useState([]);
  const [apiAccelerateData, setApiAccelerateData] = useState([]);
  const [apiServiceData, setApiServiceData] = useState([]);
  const [apiWeBragData, setApiWeBragData] = useState([]);
  const [apiOurCampaignData, setApiOurCampaignData] = useState([]);
  const [apiHeadingData, setApiHeadingData] = useState([]);

  const [apiOurVision, setApiOurVision] = useState([]);
  const [apiOurMission, setApiOurMission] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({}); // State to store validation errors


  const handleChange = (value, name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Clear mobile number error when user starts typing
    if (name === "mobile" && errors.mobile) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: "",
      }));
    }
  };

  const sendEmail = () => {
    // Send email to client
    emailjs.sendForm(
      "service_2jajma6",
      "template_kii0cau",
      form.current,
      "Zz8P39egmRcdrIvhE"
    )
    .then(
      (result) => {
        console.log(result.text);
        console.log('Client email sent successfully');
      },
      (error) => {
        console.error(error.text);
        console.error('Failed to send client email');
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform validation
    const validationErrors = {};
    if (!formData.name) {
      validationErrors.name = "Full name is required";
    }
    
    if (!formData.phone) {
      validationErrors.phone = "Mobile number is required";
    } else if (!validator.isMobilePhone(formData.phone)) {
      validationErrors.phone = "Invalid mobile number";
    }
    if (!formData.message) {
      validationErrors.message = "Message is required";
    }

     // Set validation errors
     setErrors(validationErrors);

     // If there are validation errors, prevent form submission
     if (Object.keys(validationErrors).length > 0) {
       return;
     }

    try {
      const response = await fetch(
        "https://api.youtubemarketingguy.com/api/form-submits",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: formData,
          }),
        }
      );

      if (response.ok) {
        console.log("Form submitted successfully!");
        sendEmail();
        navigate("/contactsubmit");
        // history.push('/thank');
      } else {
        console.error("Form submission failed:", response.status);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/home-pages?populate[OurVision][populate]=*"
        );
        const data = await response.json();
        // setApiMobileHomePageHeadingData(data.data);
        setApiOurVision(
          data.data[0]?.attributes.OurVision[0] || {}
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/home-pages?populate[OurMission][populate]=*"
        );
        const data = await response.json();
        setApiOurMission(
          data.data[0]?.attributes.OurMission[0] || {}
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/home-pages?populate[OUR_LATEST_CAMPAIGNS][populate]=*"
        );
        const data = await response.json();
        setApiOurCampaignData(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/home-pages?populate[HOME_BANNER][populate]=*"
        );
        const data = await response.json();
        setApiHomeBannerData(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/home-pages?populate[WE_BRAG][populate]=*"
        );
        const data = await response.json();
        setApiHeadingData(data.data[0]?.attributes || {});
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/home-pages?populate[WE_BRAG][populate]=*"
        );
        const data = await response.json();
        setApiWeBragData(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/home-pages?populate[ACCELERATE_YOUR_GROUTH][populate]=*"
        );
        const data = await response.json();
        setApiAccelerateData(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/home-pages?populate[service][populate]=*"
        );
        const data = await response.json();
        setApiServiceData(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/home-pages?populate[TrustedByTheBest][populate]=*"
        );
        const data = await response.json();
        setApiTrustedByTheBest(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/desktop-contact-uses?populate[Contact_Us_Form][populate]=*"
        );
        const data = await response.json();
        setApiContactUsData(          
          data.data[0]?.attributes.Contact_Us_Form[0] || {}
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Navbar />

      {/* Home banner */}

      <div className="home-banner">
        {apiHomeBannerData.map((item, index) => (
          <div
            key={item.id}
            className="carousel slide"
            data-bs-ride="carousel"
            id={`carouselExampleBanner${index}`}
          >
            <div className="carousel-indicators">
              {item.attributes.HOME_BANNER.map((trustedItem, i) => (
                <button
                  key={i}
                  type="button"
                  data-bs-target={`#carouselExampleBanner${index}`}
                  data-bs-slide-to={i}
                  className={i === 0 ? "active" : ""}
                  aria-current={i === 0 ? "true" : "false"}
                  aria-label={`Slide ${i + 1}`}
                ></button>
              ))}
            </div>
            <div className="carousel-inner">
              {item.attributes.HOME_BANNER.map((trustedItem, i) => (
                <div
                  key={trustedItem.id}
                  className={`carousel-item ${i === 0 ? "active" : ""}`}
                  data-bs-interval="3000"
                >
                  {trustedItem.images &&
                    trustedItem.images.data.map((image, imageIndex) => (
                      <img
                        key={imageIndex}
                        src={`https://api.youtubemarketingguy.com${image.attributes.url}`}
                        alt={`Image ${imageIndex + 1}`}
                        className="d-block w-100"
                      />
                    ))}

                  {/* <div className="carousel-caption">
                 <h4>{trustedItem.text3}</h4></div> */}
                  <div className="carousel-caption col-7">
                    {/* <h3 className="home-ban-space"> */}
                    <h4>{trustedItem.text_color_red}</h4>
                    <h3> {trustedItem.text1}</h3>
                    <h3> {trustedItem.text2}</h3>
                  </div>
                </div>
              ))}
            </div>
            <button
              className="carousel-control-prev2"
              type="button"
              data-bs-target={`#carouselExampleBanner${index}`}
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next2"
              type="button"
              data-bs-target={`#carouselExampleBanner${index}`}
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        ))}
      </div>

      {/* <div className="home-banner">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>

          <div className="carousel-inner">
            {apiData.map((item) => (
              <div
                key={item.id}
                className={`carousel-item ${item.id === 1 ? "active" : ""}`}
                data-bs-interval="3000"
              >
                <img
                  src={`https://api.youtubemarketingguy.com${item.attributes.Main_Banner[0].Heading_images.data[0].attributes.url}`}
                  className="d-block w-100"
                  alt="..."
                />
                <div className="carousel-caption">
                  <h4>{item.attributes.Home_banner_heading1}</h4>
                  <h3>{item.attributes.Main_Banner[0].Heading_text1}</h3>
                  <h3>{item.attributes.Home_banner_heading2 }</h3>
                </div>
              </div>
            ))}

            {apiData.map((item) => (
              <div
                key={item.id}
                className={`carousel-item ${item.id === 1}`}
                data-bs-interval="3000"
              >
                <img
                  src={`https://api.youtubemarketingguy.com${item.attributes.Main_Banner[0].Heading_images.data[1].attributes.url}`}
                  className="d-block w-100"
                  alt="..."
                />
                <div className="carousel-caption col-7">
                  <h3 className="home-ban-space">
                    {item.attributes.Main_Banner[0].Heading_text2}
                  </h3>
                </div>
              </div>
            ))}

            {apiData.map((item) => (
              <div
                key={item.id}
                className={`carousel-item ${item.id === 1}`}
                data-bs-interval="3000"
              >
                <img
                  src={`https://api.youtubemarketingguy.com${item.attributes.Main_Banner[0].Heading_images.data[2].attributes.url}`}
                  className="d-block w-100"
                  alt="..."
                />
                <div className="carousel-caption col-7">
                  <h3 className="home-ban-space">
                    {" "}
                    {item.attributes.Main_Banner[0].Heading_text3}
                  </h3>
                </div>
              </div>
            ))}
          </div>

          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div> */}

      {/* TRUSTED_BY_THE_BEST */}

      <div className="container pt-4 pb-5">
        <div className="row trust-row">
          <h5>{apiHeadingData.TRUSTED_BY_THE_BEST}</h5>
          <div className="marquee mt-5">
            <div className="marquee-content">
              {apiTrustedByTheBest.map((item) =>
                item.attributes.TrustedByTheBest.map(
                  (trustedItem) =>
                    trustedItem.images &&  
                    trustedItem.images.data &&
                    trustedItem.images.data.map((image, imageIndex) => (
                      <div className="marquee-item" key={imageIndex}>
                        <img
                          src={`https://api.youtubemarketingguy.com${image.attributes.url}`}
                          alt={`Image ${imageIndex + 1}`}
                        />
                      </div>
                    ))
                )
              )}
            </div>
          </div>
        </div>
      </div>

      {/* OUR SERVICESOUR SERVICES */}

      <div className="container-fluid service-container pt-5">
        
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
            <h3>{apiHeadingData.our_service}</h3>
            </div>
          </div>
        </div>
        {apiServiceData.map((item) => (
          <div key={item.id} className="row service-row pt-4">
            {item.attributes.service.map((trustedItem, index) => (
              <React.Fragment key={trustedItem.id}>
                {index % 3 === 0 && (
                  <div className="row service-row pt-4"></div>
                )}
                <div className="col-lg-3">
                  <div className="service-box">
                    {trustedItem.images &&
                      trustedItem.images.data &&
                      trustedItem.images.data.map((image, imageIndex) => (
                        <img
                          key={imageIndex}
                          src={`https://api.youtubemarketingguy.com${image.attributes.url}`}
                          alt={`Image ${imageIndex + 1}`}
                        />
                      ))}
                    <h5>{trustedItem.text1}</h5>
                    <h5>{trustedItem.text2}</h5>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        ))}
        {/* {apiServiceData.map((item) => (
    <div key={item.id} className="row service-row pt-4">
      {item.attributes.service.map((trustedItem, index) => (
        <React.Fragment key={index}>
          <div key={trustedItem.id} className="col-lg-3" >
            <div className="service-box">
              {trustedItem.images &&
                trustedItem.images.data.map((image, imageIndex) => (
                  <img
                    key={imageIndex}
                    src={`https://api.youtubemarketingguy.com${image.attributes.url}`}
                    alt={`Image ${imageIndex + 1}`}
                  />
                ))}
              <h5>{trustedItem.team_name}</h5>
            </div>
          </div>
        .
        </React.Fragment>
      ))}
    </div>
  ))} */}
      </div>

      {/* ACCELERATE YOUR YOUTUBE GROWTH WITH OUR ADD-ON SERVICES */}

      <div className="container-fluid service-container pt-4 mt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
          <h3>{apiHeadingData.ACCELERATE_YOUR}</h3>
          </div>
        </div>
      </div>
        {apiAccelerateData.map((item) => (
          <div key={item.id} className="row service-row pt-4">
            {item.attributes.ACCELERATE_YOUR_GROUTH.map(
              (trustedItem, index) => (
                <React.Fragment key={trustedItem.id}>
                  {index % 3 === 0 && (
                    <div className="row service-row pt-4"></div>
                  )}
                  <div className="col-lg-3">
                    <div className="service-box">
                      {trustedItem.images &&
                        trustedItem.images.data &&
                        trustedItem.images.data.map((image, imageIndex) => (
                          <img
                            key={imageIndex}
                            src={`https://api.youtubemarketingguy.com${image.attributes.url}`}
                            alt={`Image ${imageIndex + 1}`}
                          />
                        ))}
                      {/* <h5>{trustedItem.team_name}</h5> */}
                      <h5>
                        {trustedItem.text_col1} <br />
                        {trustedItem.text_col2}
                      </h5>
                    </div>
                  </div>
                </React.Fragment>
              )
            )}
          </div>
        ))}
      </div>

      {/* WE DON’T WANT TO BRAG BUT OUR CLIENT */}

      <div className="container-fluid test-main-container mt-5">
        <div className="container testimonial-container pt-5 pb-5">
          <h3 class="pb-4">{apiHeadingData.WE_DON}</h3>
          {apiWeBragData.map((item, index) => (
            <div
              key={item.id}
              className="carousel slide"
              data-bs-ride="carousel"
              id={`carouselExample${index}`}
            >
              <div className="carousel-indicators">
                {item.attributes.WE_BRAG.map((trustedItem, i) => (
                  <button
                    key={i}
                    type="button"
                    data-bs-target={`#carouselExample${index}`}
                    data-bs-slide-to={i}
                    className={i === 0 ? "active" : ""}
                    aria-current={i === 0 ? "true" : "false"}
                    aria-label={`Slide ${i + 1}`}
                  ></button>
                ))}
              </div>
              <div className="carousel-inner">
                {item.attributes.WE_BRAG.map((trustedItem, i) => (
                  <div
                    key={trustedItem.id}
                    className={`carousel-item ${i === 0 ? "active" : ""}`}
                    data-bs-interval="3000"
                  >
                    <div className="row test-row pt-4 pb-5">
                      <div className="col-lg-5">
                        <div className="test-box">
                          {trustedItem.images &&
                            trustedItem.images.data &&
                            trustedItem.images.data.map((image, imageIndex) => (
                              <img
                                key={imageIndex}
                                src={`https://api.youtubemarketingguy.com${image.attributes.url}`}
                                alt={`Image ${imageIndex + 1}`}
                                className="img-fluid"
                              />
                            ))}
                        </div>
                      </div>
                      <div className="col-lg-5">
                        <div className="test-box">
                          <h5>{trustedItem.heading}</h5>
                          <p className="pt-4">{trustedItem.content}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <button
                className="carousel-control-prev1"
                type="button"
                data-bs-target={`#carouselExample${index}`}
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next1"
                type="button"
                data-bs-target={`#carouselExample${index}`}
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* OUR LATEST CAMPAIGNS */}

      <div className="container-fluid mt-3">
        <div className="container campaign-container pt-5 pb-5">
          <h3>{apiHeadingData.OUR_LATEST}</h3>
          {apiOurCampaignData.map((item, index) => (
            <div
              key={item.id}
              className="carousel slide"
              data-bs-ride="carousel"
              id={`carouselCampExample${index}`}
            >
              <div className="carousel-indicators">
                {item.attributes.OUR_LATEST_CAMPAIGNS.map((trustedItem, i) => (
                  <button
                    key={i}
                    type="button"
                    data-bs-target={`#carouselCampExample${index}`}
                    data-bs-slide-to={i}
                    className={i === 0 ? "active" : ""}
                    aria-current={i === 0 ? "true" : "false"}
                    aria-label={`Slide ${i + 1}`}
                  ></button>
                ))}
              </div>
              <div className="carousel-inner">
                {item.attributes.OUR_LATEST_CAMPAIGNS.map((trustedItem, i) => (
                  <div
                    key={trustedItem.id}
                    className={`carousel-item ${i === 0 ? "active" : ""}`}
                    data-bs-interval="3000"
                  >
                    <div className="row test-row pt-4">
                      <div className="col-lg-6">
                        <div className="campaign-content">
                          <p>{trustedItem.text2}</p>
                        </div>
                      </div>
                      <div className="col-lg-5">
                        <div className="campaign-content">
                          {trustedItem.image1 &&
                            trustedItem.image1.data &&
                            trustedItem.image1.data.map((image, imageIndex) => (
                              <img
                                key={imageIndex}
                                src={`https://api.youtubemarketingguy.com${image.attributes.url}`}
                                alt={`Image ${imageIndex + 1}`}
                                className="img-fluid"
                              />
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <button
                className="carousel-control-prev1"
                type="button"
                data-bs-target={`#carouselCampExample${index}`}
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next1"
                type="button"
                data-bs-target={`#carouselCampExample${index}`}
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* <div className="container campaign-container pt-5 pb-5">
      {apiData.map((item) => (
          <React.Fragment key={item.id}>
        <h3>{item.attributes.Main_Banner[2].heading}</h3>
        <div className="row test-row pt-4">
          <div className="col-lg-7">
            <div className="campaign-content">
              <p>
              {item.attributes.Main_Banner[2].content}
              </p>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="campaign-content">
            <img
                  src={`https://api.youtubemarketingguy.com${item.attributes.Main_Banner[2].image1.data[0].attributes.url}`}
                  alt="Image not found"
                />
            </div>
          </div>
        </div>
        </React.Fragment>
        ))}
    </div> */}

      {/* LET’S WRITE OUR GROWTH - STORIES TOGETHER IN 2024 */}

      <div className="container contact-container pt-4 pb-5">
        <div className="row justify-content-center">
          <div className="col-lg-12">
          <h3> {apiContactUsData.Contact_Form_Heading}</h3>
          </div>
        </div>
        
        <div className="row contact-row pt-5">
          <div className="col-lg-6">
            <div className="contact-column">
              <h5>
              {apiContactUsData.Contact_Form_ContentText1} <br /> <b> {apiContactUsData.Contact_Form_ContentText2}</b> <br />  {apiContactUsData.Contact_Form_ContentText3}{" "}
                <br />  {apiContactUsData.Contact_Form_ContentText4}{" "}
                <b>
                {apiContactUsData.Contact_Form_ContentBoldText5} <br />  {apiContactUsData.Contact_Form_ContentBoldText6}
                </b>{" "}<br/>
                <b>{apiContactUsData.Contact_Form_ContentBoldText7}</b>{" "}
                {apiContactUsData.Contact_Form_ContentText8}
              </h5>
            </div>
          </div>
          {/* <div className="col-lg-6">
            <div className="contact-column">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    required
                    maxLength="100"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email">Business Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    required
                    maxLength="100"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="phone">Phone Number</label>
                  <PhoneInput
                    international
                    defaultCountry="IN"
                    value={formData.phone}
                    onChange={(value) => handleChange(value, "phone")} // Pass 'phone' as name
                    inputProps={{
                      id: "phone",
                      name: "phone",
                      className: "form-control",
                    }}
                    maxLength="20"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="message">Message</label>
                  <textarea
                    className="form-control"
                    rows="6"
                    placeholder="Leave a comment here"
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    required
                    maxLength="1000"
                  ></textarea>
                </div>
                <button type="submit">
                  CALL ME BACK <i className="bi bi-arrow-right-circle"></i>
                </button>
              </form> */}
              <div className="col-lg-6">
            <div className="contact-column">
              <form ref={form} onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="name"> {apiContactUsData.Contact_Form_NameText}</label>
                  <input
                    type="text"
                    placeholder="Write your name"
                    className={`form-control ${errors.name && "is-invalid"}`}
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    maxLength="100"
                  />
                  
                  {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="email"> {apiContactUsData.Contact_Form_EmailText}</label>
                  <input
                    type="email"
                    placeholder="Write your email id"
                    className= "form-control "
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    maxLength="100"
                  />
                </div>
                <div className="mb-3 app-input">
                  <label htmlFor="phone"> {apiContactUsData.Contact_Form_NumberText}</label>
                  <PhoneInput
                    international
                    defaultCountry="IN"                    
                    className={`form-control ${errors.phone && "is-invalid"}`}
                    value={formData.phone}
                    onChange={(value) => handleChange(value, "phone")} // Pass 'phone' as name
                    inputProps={{
                      id: "phone",
                      name: "phone",
                    }}
                    maxLength="20"
                  />
                  {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                </div>
                <div className="mb-5">
                  <label htmlFor="message"> {apiContactUsData.Contact_Form_MessageText}</label>
                  <textarea
                    className={`form-control ${errors.message && "is-invalid"}`}
                    rows="6"
                    placeholder="Write a comment here"
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    maxLength="1000"
                  ></textarea>
                  {errors.message && <div className="invalid-feedback">{errors.message}</div>}
                </div>
                <button type="submit">
                {apiContactUsData.Contact_Form_SubmitText} <i className="bi bi-arrow-right-circle"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OUR MISSION */}

      <div className="container-fluid mission-container">
        <div className="container mission-container mt-2 pt-4">
              <h4>{apiOurMission.heading}</h4>
              <div className="row align-items-center pt-5">
                <div className="col-lg-6">
                  <div className="mission-content">
                    <h6>{apiOurMission.content1}</h6>
                    <br></br>
                    <h5>{apiOurMission.content2}</h5>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mission-img">
                  {apiOurMission.image1 && (
                <img
                  src={`https://api.youtubemarketingguy.com${apiOurMission.image1.data[0].attributes.url}`}
                  alt="Image not found"
                />
              )}
                  </div>
                </div>
              </div>
        </div>
      </div>

      {/* OUR VISION */}

      <div className="container-fluid mission-container">
        <div className="container mt-5 pt-4">
              <h4>{apiOurVision.heading}</h4>
              <div className="row align-items-center back-row pt-5">
                <div className="col-lg-6">
                  <div className="mission-img">
                  {apiOurVision.image1 && (
                <img
                  src={`https://api.youtubemarketingguy.com${apiOurVision.image1.data[0].attributes.url}`}
                  alt="Image not found"
                />
              )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mission-content">
                    <h2> {apiOurVision.content}</h2>
                  </div>
                </div>
              </div>
        </div>
      </div>

      {/*  Our campaigns generated avg. */}
      <div className="container pt-5 mb-4">
        <div className="row justify-content-center">
          <div className="col-lg-11 cocoma-details">
            <h2>
              <i className="fa-solid fa-quote-left det-icon"></i> &nbsp;
              {apiHeadingData.OUR_CAM}
              &nbsp; <i className="fa-solid fa-quote-right det-icon1"></i>
            </h2>
          </div>
        </div>
      </div>

      {/* HOW CAN WE HELP YOU? */}
      <div className="container pt-5 pb-5">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="help-btn">
              <Link to="/contact">
                <button>
                  {apiHeadingData.help_you} {''} {''}
                  <i className="bi bi-arrow-right-circle"></i>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Home;
