import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';


const Contactsubmit = () => {
    const [apiContactSubmitData, setApiContactSubmitData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/desktop-contact-uses?populate[Contact_ThankPage][populate]=*"
        );
        const data = await response.json();
        setApiContactSubmitData(
          data.data[0]?.attributes.Contact_ThankPage[0] || {}
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
   <>
   <Navbar/>

   <div class="container mb-5">
        <div class="row justify-content-center pt-5 pb-5">
            <div class="col-lg-7">
                <div class="submitted-box">
                {/* <img src="images/thanks.png" alt="" /> */}
                {apiContactSubmitData.Thank_Image && (
                  <img
                    src={`https://api.youtubemarketingguy.com${apiContactSubmitData.Thank_Image.data[0].attributes.url}`}
                    alt="Image not found"
                  />
                )}
                    <h6 className='pt-4'>{apiContactSubmitData.Thank_Text1} <br/>
                    {apiContactSubmitData.Thank_Text2}</h6>
                </div>
            </div>
        </div>
    </div>

   <Footer/>
   </>
  )
}

export default Contactsubmit