import './App.css';
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./component/Home";
import ThankYouPage from "./component/ThankYouPage";
import Ourwork from "./component/Ourwork";
import Services from "./component/Services";
import About from './component/About';
import Contact from './component/Contact';
import Singleservice from './component/Singleservice';
import Career from './component/Career';
import Jobdescription from './component/Jobdescription';
import Applicationform from './component/Applicationform';
import Contactsubmit from './component/Contactsubmit';
import Test from './component/Test';
import Blog from './component/Blog';

const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/ourwork" element={<Ourwork />} />
          <Route path="/service" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/singleservice/:serviceId" element={<Singleservice />} />
          <Route path="/career" element={<Career />} />
          <Route path="/jobdescription/:jobId" element={<Jobdescription />} />
          <Route path="/applicationform/:jobId" element={<Applicationform />} />
          <Route path="/contactsubmit" element={<Contactsubmit />} />
          <Route path="/thank" element={<ThankYouPage />} />
          <Route path="/test" element={<Test />} />
          <Route path="/blog" element={<Blog/>}/>
        </Routes>
      </Router>
    </div>
  );
};

export default App;

