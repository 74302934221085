import React, { useState, useEffect } from "react";
import Navbar from './Navbar'
import Footer from './Footer'
import { Link } from "react-router-dom";
import axios from 'axios';

const Ourwork = () => {

  const [apiData, setApiData] = useState([]);
  const [apiImpageData, setApiImpageData] = useState([]);
  const [apiSportData, setApiSportData] = useState([]);
  const [apiSongMusictData, setApiSongMusictData] = useState([]);
  const [apiHeadingData, setApiHeadingData] = useState([]);

  const [showAll, setShowAll] = useState(false);
  const [showAllSport, setShowAllSport] = useState(false);
  const [showAllMusic, setShowAllMusic] = useState(false);

  const [uniqueYears, setUniqueYears] = useState([]);
  const [showAllFilms, setShowAllFilms] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);

  const handleSeeMoreClick = () => {
    setShowAll(prevShowAll => !prevShowAll);
  };

  const handleSportSeeMoreClick = () => {
    setShowAllSport(prevShowAllSport => !prevShowAllSport);
  };

  const handleMusicSeeMoreClick = () => {
    setShowAllMusic(prevShowAllMusic => !prevShowAllMusic);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/our-works"
        );
        const data = await response.json();
        setApiHeadingData(data.data[0]?.attributes || {});
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          // "https://api.youtubemarketingguy.com/api/our-works?populate[SONGS_MUSIC_VIDEOS_MARKETING][populate]=*"
          "https://api.youtubemarketingguy.com/api/song-music-videos-listings?populate=*&pagination[pageSize]=100000"
        );
        const data = await response.json();
        setApiSongMusictData(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/sports-tournaments-listings?populate=*&pagination[pageSize]=100000"
          // "https://api.youtubemarketingguy.com/api/our-works?populate[SPORTS_TOURNAMENTS_MARKETING][populate]=*"
        );
        const data = await response.json();
        setApiSportData(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          // "https://api.youtubemarketingguy.com/api/our-works?populate[GROWTH_PARTNERS][populate]=*"
          "https://api.youtubemarketingguy.com/api/growth-partners-listings?populate=*&pagination[pageSize]=500"
        );
        const data = await response.json();
        setApiImpageData(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  
  
  const handleFilmsSeeMoreClick = () => {
    
    setShowAllFilms(prevShowAllFilms => !prevShowAllFilms);
  };

   // Function to handle year click
   const handleYearClick = (year) => {
    // If the same year is clicked again, toggle showAllFilms
    if (year === selectedYear) {
      setShowAllFilms(!showAllFilms);
    } else {
      setSelectedYear(year);
      // Reset showAllFilms to false when a new year is selected
      setShowAllFilms(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          // "https://api.youtubemarketingguy.com/api/our-works?populate[FILMS_WEB_SERIES][populate]=*"
          "https://api.youtubemarketingguy.com/api/films-series-tv-listings?populate=*&pagination[pageSize]=100000"
        );
        const data = await response.json();
        setApiData(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const years = [
      ...new Set(
        apiData.map((item) => item.attributes.Film_ReleaseYear)
      ),
    ].sort((a, b) => b - a);
    setUniqueYears(years);
    setSelectedYear(years[0]);
    
  }, [apiData]);


  return (
    <>
    
    <Navbar/>

    <div className="container">
      <div className="row justify-content-center mt-4">
        <div className="col-lg-11 pt-4">
          <div className="work-headings">
            <h5>
              {apiHeadingData.Main_Heading_Content}
            </h5>
          </div>
        </div>
      </div>
    </div>

{/* WE’RE OFFICIAL YOUTUBE GROWTH PARTNERS FOR */}


    <div className="container prime-container pt-5 pb-5">
      <div className="col-lg-10 partner-box">
        <h2>
        {apiHeadingData.Growth_Partners_Heading}
        </h2>
      </div>
      <div className="row prime-row">
        {/* {apiImpageData.map((item) => (
          <div className="row prime-row" key={item.id}>
            {item.attributes.GROWTH_PARTNERS.slice(0, showAll ? undefined : 4).map((imageIteam, index) => (
              <React.Fragment key={index}>
                <div className="col-lg-4">
                  <div className="prime-img1" style={{ backgroundColor: imageIteam.color.toLowerCase() }}>
                    {imageIteam.images && imageIteam.images.data &&
                      imageIteam.images.data.map((image, imageIndex) => (
                        <img
                          key={imageIndex}
                          src={`https://api.youtubemarketingguy.com${image.attributes.url}`}
                          alt={`Image ${imageIndex + 1}`}
                        />
                      ))}
                  </div>
                </div>
                {index % 2 !== 0 && <div className="w-100"></div>}
              </React.Fragment>
            ))}
          </div>
        ))} */}
          <div className="row prime-row">
            {apiImpageData.slice(0, showAll ? undefined : 4).map((item,index) => (
              <React.Fragment key={index}>
                <div className="col-lg-4">
                  <div className="prime-img1" style={{ backgroundColor: item.attributes.color.toLowerCase() }}>
                  {item.attributes.images && item.attributes.images.data &&
                item.attributes.images.data.map((image, imageIndex) => (
                        <img
                          key={imageIndex}
                          src={`https://api.youtubemarketingguy.com${image.attributes.url}`}
                          alt={`Image ${imageIndex + 1}`}
                        />
                      ))}
                  </div>
                </div>
                {index % 2 !== 0 && <div className="w-100"></div>}
              </React.Fragment>
            ))}
          </div>
      </div>
      {/* <div className="row justify-content-center pt-4">
        <div className="col-lg-4">
          <div className="see-box" onClick={handleSeeMoreClick}>
            <h4>
              see more <i className="bi bi-arrow-down-circle-fill"></i>
            </h4>
          </div>
        </div>
      </div> */}
      <div className="row justify-content-center pt-4">
        <div className="col-lg-4">
          <div className="see-box" onClick={handleSeeMoreClick}>
            <h4>
              {showAll ? 'See less' : 'See more'}{' '}
              <i className={`bi bi-arrow-${showAll ? 'up' : 'down'}-circle-fill`}></i>
            </h4>
          </div>
        </div>
      </div>
    </div>

{/* FILMS, WEB-SERIES, TV SHOWS MARKETING */}

    <div className="container">
        <div className="film-heading">
          <h3> {apiHeadingData.Films_Series_TV_Heading}</h3>
          <h6 className="pt-3"> {apiHeadingData.Films_Series_TV_Contents}</h6>
          </div>
          <div className="row pt-4 mt-4">
          <div className="col-lg-12 pb-5">
            <div className="years-slider-box">
              {uniqueYears.map((Film_ReleaseYear) => (
                <div
                  key={Film_ReleaseYear}
                  className={`year-box ${
                    selectedYear === Film_ReleaseYear ? "active-year" : ""
                  }`}
                  onClick={() => handleYearClick(Film_ReleaseYear)}
                >
                  <h6>{Film_ReleaseYear}</h6>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* <div className="row fall-row">
          {apiData.map((item) =>
            item.attributes.FILMS_WEB_SERIES.filter((trustedItem) =>
              selectedYear ? trustedItem.year === selectedYear : true
            )
              // .slice(0, 12)
              .slice(0, showAllFilms ? undefined : 12)
              .map((trustedItem) => (
                <div key={trustedItem.id} className="col-lg-2 col-md-4 col-6">
                  <div className="fallout-box">
                    {trustedItem.images &&
                      trustedItem.images.data.map((image, imageIndex) => (
                        <img
                          key={imageIndex}
                          src={`https://api.youtubemarketingguy.com${image.attributes.url}`}
                          alt={`Image ${imageIndex + 1}`}
                        />
                      ))}
                    <p>{trustedItem.text1}</p>
                    <p>{trustedItem.text2}</p>
                    <div className="fallout-btn">
                      <button>
                        <span class="btn-text">Our work</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="12"
                          viewBox="0 0 13 12"
                          fill="none"
                        >
                          <path
                            d="M10.1627 10.667H2.17473C1.99826 10.6665 1.82916 10.596 1.70438 10.4711C1.57959 10.3461 1.50926 10.1767 1.50873 10V2C1.50873 1.633 1.80828 1.333 2.17473 1.333H5.50272C5.86917 1.333 6.16872 1.033 6.16872 0.667C6.16872 0.3 5.86917 0 5.50272 0H1.50873C0.769844 0 0.177734 0.6 0.177734 1.333V10.667C0.177734 11.4 0.776833 12 1.50873 12H10.8287C11.5606 12 12.1597 11.4 12.1597 10.667V6.667C12.1597 6.3 11.8602 6 11.4937 6C11.1283 6 10.8287 6.3 10.8287 6.667V10C10.8287 10.367 10.5292 10.667 10.1627 10.667ZM7.49972 0.667C7.49972 1.033 7.79927 1.333 8.16571 1.333H9.89012L3.81227 7.42C3.75064 7.48172 3.70175 7.555 3.6684 7.63564C3.63504 7.71628 3.61788 7.80271 3.61788 7.89C3.61788 7.97729 3.63504 8.06372 3.6684 8.14436C3.70175 8.22501 3.75064 8.29828 3.81227 8.36C3.87389 8.42172 3.94706 8.47068 4.02758 8.50408C4.1081 8.53749 4.1944 8.55468 4.28156 8.55468C4.36871 8.55468 4.45502 8.53749 4.53554 8.50408C4.61606 8.47068 4.68922 8.42172 4.75085 8.36L10.8287 2.273V4C10.8287 4.367 11.1283 4.667 11.4937 4.667C11.8602 4.667 12.1597 4.367 12.1597 4V0H8.16571C7.79927 0 7.49972 0.3 7.49972 0.667Z"
                            fill="#040404"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              ))
          )}
        </div> */}

<div className="row fall-row">
  {/* {apiData.map((item) =>
    item.attributes.FILMS_WEB_SERIES.filter((trustedItem) =>
      selectedYear ? trustedItem.year === selectedYear : true
    )
    .slice(0, showAllFilms ? undefined : 12)
    .map((trustedItem) => (
      <div key={trustedItem.id} className="col-lg-2 col-md-4 col-6">
        <div className="fallout-box">
          {trustedItem.images && trustedItem.images.data && trustedItem.images.data.map((image, imageIndex) => (
            <img
              key={imageIndex}
              src={`https://api.youtubemarketingguy.com${image.attributes.url}`}
              alt={`Image ${imageIndex + 1}`}
            />
          ))}
          <p>{trustedItem.text1}</p>
          <p>{trustedItem.text2}</p>
          <div className="fallout-btn">
            <button>
              <span class="btn-text">Our work</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
              >
                <path
                  d="M10.1627 10.667H2.17473C1.99826 10.6665 1.82916 10.596 1.70438 10.4711C1.57959 10.3461 1.50926 10.1767 1.50873 10V2C1.50873 1.633 1.80828 1.333 2.17473 1.333H5.50272C5.86917 1.333 6.16872 1.033 6.16872 0.667C6.16872 0.3 5.86917 0 5.50272 0H1.50873C0.769844 0 0.177734 0.6 0.177734 1.333V10.667C0.177734 11.4 0.776833 12 1.50873 12H10.8287C11.5606 12 12.1597 11.4 12.1597 10.667V6.667C12.1597 6.3 11.8602 6 11.4937 6C11.1283 6 10.8287 6.3 10.8287 6.667V10C10.8287 10.367 10.5292 10.667 10.1627 10.667ZM7.49972 0.667C7.49972 1.033 7.79927 1.333 8.16571 1.333H9.89012L3.81227 7.42C3.75064 7.48172 3.70175 7.555 3.6684 7.63564C3.63504 7.71628 3.61788 7.80271 3.61788 7.89C3.61788 7.97729 3.63504 8.06372 3.6684 8.14436C3.70175 8.22501 3.75064 8.29828 3.81227 8.36C3.87389 8.42172 3.94706 8.47068 4.02758 8.50408C4.1081 8.53749 4.1944 8.55468 4.28156 8.55468C4.36871 8.55468 4.45502 8.53749 4.53554 8.50408C4.61606 8.47068 4.68922 8.42172 4.75085 8.36L10.8287 2.273V4C10.8287 4.367 11.1283 4.667 11.4937 4.667C11.8602 4.667 12.1597 4.367 12.1597 4V0H8.16571C7.79927 0 7.49972 0.3 7.49972 0.667Z"
                  fill="#040404"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    ))
  )} */}

  {apiData.map((item) =>
          item.attributes.Film_ReleaseYear === selectedYear && (
            <div key={item.id} className="col-lg-2 col-md-4 col-6">
              <div className="fallout-box">
                {item.attributes.Film_miniPoster &&
                  item.attributes.Film_miniPoster.data.map((image, imageIndex) => (
                    <img
                      key={imageIndex}
                      src={`https://api.youtubemarketingguy.com${image.attributes.url}`}
                      alt={`Image ${imageIndex + 1}`}
                    />
                  ))}
                <p>{item.attributes.Film_Name}</p>
                <p>{item.attributes.Film_Genres}</p>
                <div className="fallout-btn">
                  <button>
                    <span className="btn-text">Our work</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="12"
                      viewBox="0 0 13 12"
                      fill="none"
                    >
                     <path
                  d="M10.1627 10.667H2.17473C1.99826 10.6665 1.82916 10.596 1.70438 10.4711C1.57959 10.3461 1.50926 10.1767 1.50873 10V2C1.50873 1.633 1.80828 1.333 2.17473 1.333H5.50272C5.86917 1.333 6.16872 1.033 6.16872 0.667C6.16872 0.3 5.86917 0 5.50272 0H1.50873C0.769844 0 0.177734 0.6 0.177734 1.333V10.667C0.177734 11.4 0.776833 12 1.50873 12H10.8287C11.5606 12 12.1597 11.4 12.1597 10.667V6.667C12.1597 6.3 11.8602 6 11.4937 6C11.1283 6 10.8287 6.3 10.8287 6.667V10C10.8287 10.367 10.5292 10.667 10.1627 10.667ZM7.49972 0.667C7.49972 1.033 7.79927 1.333 8.16571 1.333H9.89012L3.81227 7.42C3.75064 7.48172 3.70175 7.555 3.6684 7.63564C3.63504 7.71628 3.61788 7.80271 3.61788 7.89C3.61788 7.97729 3.63504 8.06372 3.6684 8.14436C3.70175 8.22501 3.75064 8.29828 3.81227 8.36C3.87389 8.42172 3.94706 8.47068 4.02758 8.50408C4.1081 8.53749 4.1944 8.55468 4.28156 8.55468C4.36871 8.55468 4.45502 8.53749 4.53554 8.50408C4.61606 8.47068 4.68922 8.42172 4.75085 8.36L10.8287 2.273V4C10.8287 4.367 11.1283 4.667 11.4937 4.667C11.8602 4.667 12.1597 4.367 12.1597 4V0H8.16571C7.79927 0 7.49972 0.3 7.49972 0.667Z"
                  fill="#040404"
                />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          )
        )}
</div>
        <div className="row justify-content-center pt-4">
        <div className="col-lg-4">
          <div className="see-box" onClick={handleFilmsSeeMoreClick}>
            <h4>
              {showAllFilms ? 'See less' : 'See more'}{' '}
              <i className={`bi bi-arrow-${showAllFilms ? 'up' : 'down'}-circle-fill`}></i>
            </h4>
          </div>
        </div>
      </div>
      </div>

{/* SPORTS & TOURNAMENTS MARKETING */}
        
    <div class="container-fluid mt-5 pt-5 pb-5 sports-container">
      <div class="container">
        <div class="sports-heading">
            <h4> {apiHeadingData.Sports_Tournaments_Heading}</h4>
            <h6 className="pt-3">
            {apiHeadingData.Sports_Tournaments_Content}
            </h6>
        </div>
        <div class="row pt-5">
  {/* {apiSportData.map((item) => (
    item.attributes.SPORTS_TOURNAMENTS_MARKETING
      .slice(0, showAllSport ? undefined : 6)
      .map((trustedItem) => (
        <div key={trustedItem.id} className="col-lg-4 col-md-6 col-6">
          <div className="fallout-box">
            {trustedItem.images && trustedItem.images.data &&
              trustedItem.images.data.map((image, imageIndex) => (
                <img
                  key={imageIndex}
                  src={`https://api.youtubemarketingguy.com${image.attributes.url}`}
                  alt={`Image ${imageIndex + 1}`}
                />
              ))}
            <p>{trustedItem.text1}</p>
            <p>{trustedItem.text2}</p>
            <div className="fallout-btn cricket-btn">
              <button>
              <span class="btn-text">Our work</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="12"
                  viewBox="0 0 13 12"
                  fill="none"
                >
                  <path
                    d="M10.1627 10.667H2.17473C1.99826 10.6665 1.82916 10.596 1.70438 10.4711C1.57959 10.3461 1.50926 10.1767 1.50873 10V2C1.50873 1.633 1.80828 1.333 2.17473 1.333H5.50272C5.86917 1.333 6.16872 1.033 6.16872 0.667C6.16872 0.3 5.86917 0 5.50272 0H1.50873C0.769844 0 0.177734 0.6 0.177734 1.333V10.667C0.177734 11.4 0.776833 12 1.50873 12H10.8287C11.5606 12 12.1597 11.4 12.1597 10.667V6.667C12.1597 6.3 11.8602 6 11.4937 6C11.1283 6 10.8287 6.3 10.8287 6.667V10C10.8287 10.367 10.5292 10.667 10.1627 10.667ZM7.49972 0.667C7.49972 1.033 7.79927 1.333 8.16571 1.333H9.89012L3.81227 7.42C3.75064 7.48172 3.70175 7.555 3.6684 7.63564C3.63504 7.71628 3.61788 7.80271 3.61788 7.89C3.61788 7.97729 3.63504 8.06372 3.6684 8.14436C3.70175 8.22501 3.75064 8.29828 3.81227 8.36C3.87389 8.42172 3.94706 8.47068 4.02758 8.50408C4.1081 8.53749 4.1944 8.55468 4.28156 8.55468C4.36871 8.55468 4.45502 8.53749 4.53554 8.50408C4.61606 8.47068 4.68922 8.42172 4.75085 8.36L10.8287 2.273V4C10.8287 4.367 11.1283 4.667 11.4937 4.667C11.8602 4.667 12.1597 4.367 12.1597 4V0H8.16571C7.79927 0 7.49972 0.3 7.49972 0.667Z"
                    fill="#040404"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      ))
  ))} */}
  {/* {apiSportData.map((item) => ( */}
  {apiSportData.slice(0, showAllSport ? undefined : 6).map((item) => (
  <div key={item.id} className="col-lg-4 col-md-6 col-6">
    <div className="fallout-box">
      {item.attributes.images && item.attributes.images.data &&
        item.attributes.images.data.map((image, imageIndex) => (
          <img
            key={imageIndex}
            src={`https://api.youtubemarketingguy.com${image.attributes.url}`}
            alt={`Image ${imageIndex + 1}`}
          />
        ))}
      <p>{item.attributes.text1}</p>
      <p>{item.attributes.text2}</p>
      <div className="fallout-btn cricket-btn">
        <button>
          <span className="btn-text">Our work</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
          >
            <path
              d="M10.1627 10.667H2.17473C1.99826 10.6665 1.82916 10.596 1.70438 10.4711C1.57959 10.3461 1.50926 10.1767 1.50873 10V2C1.50873 1.633 1.80828 1.333 2.17473 1.333H5.50272C5.86917 1.333 6.16872 1.033 6.16872 0.667C6.16872 0.3 5.86917 0 5.50272 0H1.50873C0.769844 0 0.177734 0.6 0.177734 1.333V10.667C0.177734 11.4 0.776833 12 1.50873 12H10.8287C11.5606 12 12.1597 11.4 12.1597 10.667V6.667C12.1597 6.3 11.8602 6 11.4937 6C11.1283 6 10.8287 6.3 10.8287 6.667V10C10.8287 10.367 10.5292 10.667 10.1627 10.667ZM7.49972 0.667C7.49972 1.033 7.79927 1.333 8.16571 1.333H9.89012L3.81227 7.42C3.75064 7.48172 3.70175 7.555 3.6684 7.63564C3.63504 7.71628 3.61788 7.80271 3.61788 7.89C3.61788 7.97729 3.63504 8.06372 3.6684 8.14436C3.70175 8.22501 3.75064 8.29828 3.81227 8.36C3.87389 8.42172 3.94706 8.47068 4.02758 8.50408C4.1081 8.53749 4.1944 8.55468 4.28156 8.55468C4.36871 8.55468 4.45502 8.53749 4.53554 8.50408C4.61606 8.47068 4.68922 8.42172 4.75085 8.36L10.8287 2.273V4C10.8287 4.367 11.1283 4.667 11.4937 4.667C11.8602 4.667 12.1597 4.367 12.1597 4V0H8.16571C7.79927 0 7.49972 0.3 7.49972 0.667Z"
              fill="#040404"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
))}

</div>
          <div className="row justify-content-center pt-4">
        <div className="col-lg-4">
          <div className="see-box" onClick={handleSportSeeMoreClick}>
            <h4>
              {showAllSport ? 'See less' : 'See more'}{' '}
              <i className={`bi bi-arrow-${showAllSport ? 'up' : 'down'}-circle-fill`}></i>
            </h4>
          </div>
        </div>
        </div>
      </div>
    </div>

{/* SONGS & MUSIC VIDEOS MARKETING */}
    <div class="container pt-5 pb-5">
        <div class="sports-heading">
            <h4> {apiHeadingData.Song_Music_Videos_Heading}</h4>
            <h6 className="pt-3">
            {apiHeadingData.Song_Music_Videos_Content}
            </h6>
        </div>
        <div class="row pt-5">
  {/* {apiSongMusictData.map((item) => (
    item.attributes.SONGS_MUSIC_VIDEOS_MARKETING
      .slice(0, showAllMusic ? undefined : 12)
      .map((trustedItem) => (
        <div key={trustedItem.id} className="col-lg-2 col-md-4 col-6">
          <div className="fallout-box song-box">
            {trustedItem.images && trustedItem.images.data &&
              trustedItem.images.data.map((image, imageIndex) => (
                <img
                  key={imageIndex}
                  src={`https://api.youtubemarketingguy.com${image.attributes.url}`}
                  alt={`Image ${imageIndex + 1}`}
                />
              ))}
           <p class="text-center">{trustedItem.text}</p>
            <div class="fallout-btn pt-4">
                    <button>
                    <span class="btn-text">Our work</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                      >
                        <path
                          d="M10.1627 10.667H2.17473C1.99826 10.6665 1.82916 10.596 1.70438 10.4711C1.57959 10.3461 1.50926 10.1767 1.50873 10V2C1.50873 1.633 1.80828 1.333 2.17473 1.333H5.50272C5.86917 1.333 6.16872 1.033 6.16872 0.667C6.16872 0.3 5.86917 0 5.50272 0H1.50873C0.769844 0 0.177734 0.6 0.177734 1.333V10.667C0.177734 11.4 0.776833 12 1.50873 12H10.8287C11.5606 12 12.1597 11.4 12.1597 10.667V6.667C12.1597 6.3 11.8602 6 11.4937 6C11.1283 6 10.8287 6.3 10.8287 6.667V10C10.8287 10.367 10.5292 10.667 10.1627 10.667ZM7.49972 0.667C7.49972 1.033 7.79927 1.333 8.16571 1.333H9.89012L3.81227 7.42C3.75064 7.48172 3.70175 7.555 3.6684 7.63564C3.63504 7.71628 3.61788 7.80271 3.61788 7.89C3.61788 7.97729 3.63504 8.06372 3.6684 8.14436C3.70175 8.22501 3.75064 8.29828 3.81227 8.36C3.87389 8.42172 3.94706 8.47068 4.02758 8.50408C4.1081 8.53749 4.1944 8.55468 4.28156 8.55468C4.36871 8.55468 4.45502 8.53749 4.53554 8.50408C4.61606 8.47068 4.68922 8.42172 4.75085 8.36L10.8287 2.273V4C10.8287 4.367 11.1283 4.667 11.4937 4.667C11.8602 4.667 12.1597 4.367 12.1597 4V0H8.16571C7.79927 0 7.49972 0.3 7.49972 0.667Z"
                          fill="#040404"
                        />
                      </svg>
                    </button>
                  </div>
          </div>
        </div>
      ))
  ))} */}
 
        {apiSongMusictData.slice(0, showAllMusic ? undefined : 12).map((item) => (
        <div key={item.id} className="col-lg-2 col-md-4 col-6">
          <div className="fallout-box song-box">
            {/* Assuming there's an image URL in the data, update the src attribute accordingly */}
            {item.attributes.images && item.attributes.images.data &&
                 item.attributes.images.data.map((image, imageIndex) => (
                <img
                  key={imageIndex}
                  src={`https://api.youtubemarketingguy.com${image.attributes.url}`}
                  alt={`Image ${imageIndex + 1}`}
                />
              ))}
           <p class="text-center">{item.attributes.text}</p>
            <div class="fallout-btn pt-4">
                    <button>
                    <span class="btn-text">Our work</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                      >
                        <path
                          d="M10.1627 10.667H2.17473C1.99826 10.6665 1.82916 10.596 1.70438 10.4711C1.57959 10.3461 1.50926 10.1767 1.50873 10V2C1.50873 1.633 1.80828 1.333 2.17473 1.333H5.50272C5.86917 1.333 6.16872 1.033 6.16872 0.667C6.16872 0.3 5.86917 0 5.50272 0H1.50873C0.769844 0 0.177734 0.6 0.177734 1.333V10.667C0.177734 11.4 0.776833 12 1.50873 12H10.8287C11.5606 12 12.1597 11.4 12.1597 10.667V6.667C12.1597 6.3 11.8602 6 11.4937 6C11.1283 6 10.8287 6.3 10.8287 6.667V10C10.8287 10.367 10.5292 10.667 10.1627 10.667ZM7.49972 0.667C7.49972 1.033 7.79927 1.333 8.16571 1.333H9.89012L3.81227 7.42C3.75064 7.48172 3.70175 7.555 3.6684 7.63564C3.63504 7.71628 3.61788 7.80271 3.61788 7.89C3.61788 7.97729 3.63504 8.06372 3.6684 8.14436C3.70175 8.22501 3.75064 8.29828 3.81227 8.36C3.87389 8.42172 3.94706 8.47068 4.02758 8.50408C4.1081 8.53749 4.1944 8.55468 4.28156 8.55468C4.36871 8.55468 4.45502 8.53749 4.53554 8.50408C4.61606 8.47068 4.68922 8.42172 4.75085 8.36L10.8287 2.273V4C10.8287 4.367 11.1283 4.667 11.4937 4.667C11.8602 4.667 12.1597 4.367 12.1597 4V0H8.16571C7.79927 0 7.49972 0.3 7.49972 0.667Z"
                          fill="#040404"
                        />
                      </svg>
                    </button>
                  </div>
          </div>
        </div>
  ))}
</div>

<div class="row justify-content-center pt-4">
            <div class="col-lg-5">
            <div className="see-box" onClick={handleMusicSeeMoreClick}>
                {/* <h4>see more <i class="bi bi-arrow-down-circle-fill"></i></h4> */}
                <h4>
              {showAllMusic ? 'See less' : 'See more'}{' '}
              <i className={`bi bi-arrow-${showAllMusic ? 'up' : 'down'}-circle-fill`}></i>
            </h4>
              </div>
            <div class="help-btn pt-5">
                <Link to="/contact"><button>WORK WITH US NOW{" "}<i className="bi bi-arrow-right-circle"></i></button></Link>
            </div>
            </div>
          </div>
    </div>

<Footer/>
</>
    );
};

export default Ourwork;