// import React, { useState } from 'react';
// import axios from 'axios';

// // const Test = () => {

//   function Test({ evtId }) {
//     const [image, setImage] = useState(null)
//     const [posts, setPosts] = useState([])
//     const [updatesData, setUpdatesData] = useState({
//       fname: "",
//     })
  
//     function updateEdit(e) {
  
//       const newupdate = { ...updatesData }
//       newupdate[e.target.id] = e.target.value
//       setUpdatesData(newupdate)
//       console.log(newupdate)
//     }
  
//     const handleSubmit = async (e) => {
//       console.log('handleSubmit')
//       e.preventDefault()
  
//       const formData = new FormData()
//       formData.append('files', image) // the pic
//       formData.append('ref', 'api::product-group.product-group') // link with my table
//       formData.append('refId', evtId)
//       //formData.append('field', 'picproductgroup') // the row
//       axios.post('https://api.youtubemarketingguy.com/api/upload/', formData).then(res => {
//         console.log(res.data[0].id);
//         const res2 = axios.post(`https://api.youtubemarketingguy.com/api/jobs-forms`, {
//           "data": {
//             fname: updatesData.fname,
//             fname: res.data[0].id,
//           }
//         })
  
//         if (res2.ok) {
//           console.log('res.ok')
//           console.log('res', res2)
//           // imageUploaded()
//         }
//       }).catch(error => {
//         console.log(error.message);
//       });
  
  
//       //e.preventDefault()
//     }
  
//     const handleFileChange = (e) => {
//       console.log('handleFileChange')
//       console.log(e.target.files[0]) //this will give us an array and we want the first wone so we add 0
//       setImage(e.target.files[0])
//     }
//     return (
//       <div>
//         <h1> Upload Event Image</h1>
  
//         <form onSubmit={handleSubmit}>
//           <input onChange={(e) => updateEdit(e)} id="fname" value={updatesData.fname} type="text" placeholder={posts.fname} />
//           <div>
//             <input type='file' onChange={handleFileChange} />
//           </div>
//           <input type='submit' value='Upload' className='btn' />
//         </form>
//       </div>
//     )
//   }
  
// export default Test;


// import React, { useState } from 'react';
// import axios from 'axios';

// function Test({ evtId }) {
//   const [image, setImage] = useState(null);
//   const [updatesData, setUpdatesData] = useState({
//     fname: "",
//   });
//   const updateEdit = (e) => {
//     const newUpdate = { ...updatesData };
//     newUpdate[e.target.id] = e.target.value;
//     setUpdatesData(newUpdate);
//   };
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
//     formData.append('files', image);
//     formData.append('ref', 'api::product-group.product-group');
//     formData.append('refId', evtId);
//     try {
//       const uploadResponse = await axios.post('https://api.youtubemarketingguy.com/api/upload/', formData);
//       const fileId = uploadResponse.data[0].id;
//       const formResponse = await axios.post('https://api.youtubemarketingguy.com/api/jobs-forms', {
//         fname: updatesData.fname,
//         fileId: fileId
//       });
//       console.log(formResponse);
//       setUpdatesData({ fname: "" });
//       setImage(null);
//     } catch (error) {
//       console.error('Error:', error);
//     }
//   };
//   const handleFileChange = (e) => {
//     setImage(e.target.files[0]);
//   };

//   return (
//     <div>
//       <h1>Upload Event Image</h1>
//       <form onSubmit={handleSubmit}>
//         <input onChange={updateEdit} id="fname" value={updatesData.fname} type="text" placeholder="Enter Name" />
//         <div>
//           <input type='file' onChange={handleFileChange} />
//         </div>
//         <input type='submit' value='Upload' className='btn' />
//       </form>
//     </div>
//   );
// }
// export default Test;





import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Test() {
  
  const navigate = useNavigate();
  const [fname, setFname] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data:", { fname, selectedFile });

    // Post form data
    axios.post('https://api.youtubemarketingguy.com/api/jobs-forms', { fname })
      .then(res => {
        console.log(res);
        return res.data.user.id;
      })
      .then(refId => {
        // Post file data
        const formData = new FormData();
        formData.append('files', selectedFile);
        formData.append('refId', refId);
        formData.append('ref', 'user');
        formData.append('source', 'users-permissions');
        formData.append('field', 'profileImage');
        return axios.post('https://api.youtubemarketingguy.com/api/upload', formData);
      })
      .then(res => {
        console.log(res);
        alert("You registered successfully...");
        navigate("/thank");
        // Navigate to login page after successful upload
        // You may need to use React Router for navigation
        // this.props.history.push('/login');
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <div>
      <h1>Upload Event Image</h1>
      <form onSubmit={handleSubmit}>
        <input
          id="fname"
          value={fname}
          onChange={(e) => setFname(e.target.value)}
          type="text"
          placeholder="Enter Name"
        />
        <div>
          <input type='file' onChange={handleFileChange} />
        </div>
        <input type='submit' value='Upload' className='btn' />
      </form>
    </div>
  );
}

export default Test;

