import React, { useEffect, useState, useRef} from "react";
import emailjs from '@emailjs/browser'
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import Navbar from "./Navbar";
import Footer from "./Footer";
import validator from "validator"; // Import validator library for additional validation

const Contact = () => {
  
  const [apiContactUsData, setApiContactUsData] = useState({});
  const navigate = useNavigate();
  const form = useRef();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = (value, name) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));

    // Clear validation error when user starts typing
    if (errors[name]) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: ''
      }));
    }
  };

  const sendEmail = () => {
    // Send email to client
    emailjs.sendForm(
      "service_2jajma6",
      "template_kii0cau",
      form.current,
      "Zz8P39egmRcdrIvhE"
    )
    .then(
      (result) => {
        console.log(result.text);
        console.log('Client email sent successfully');
      },
      (error) => {
        console.error(error.text);
        console.error('Failed to send client email');
      }
    );
//  // Send email to user
//  emailjs.send(
//   'YOUR_SERVICE_ID',
//   'USER_TEMPLATE_ID',
//   {
//     to_email: formData.email, // Assuming formData.email contains user's email
//     message: 'Thank you for reaching out. Our team will contact you back soon.'
//   },
//   'YOUR_USER_ID'
// )
// .then(
//   (result) => {
//     console.log(result.text);
//     console.log('User email sent successfully');
//   },
//   (error) => {
//     console.error(error.text);
//     console.error('Failed to send user email');
//   }
// );
};

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform validation
    const validationErrors = {};
    if (!formData.name) {
      validationErrors.name = 'Full name is required';
    }
    if (!formData.email) {
      validationErrors.email = 'Email is required';
    } else if (!validator.isEmail(formData.email)) {
      validationErrors.email = 'Invalid email address';
    }
    if (!formData.phone) {
      validationErrors.phone = 'Mobile number is required';
    } else if (!validator.isMobilePhone(formData.phone)) {
      validationErrors.phone = 'Invalid mobile number';
    }
    if (!formData.message) {
      validationErrors.message = 'Message is required';
    }

    // Set validation errors
    setErrors(validationErrors);

    // If there are validation errors, prevent form submission
    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    try {
      const response = await fetch('https://api.youtubemarketingguy.com/api/form-submits', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: formData
        })
      });

      if (response.ok) {
        console.log('Form submitted successfully!');
        sendEmail(); // Send email after successful form submission
        navigate('/contactsubmit');
      } else {
        console.error('Form submission failed:', response.status);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/desktop-contact-uses?populate[Contact_Us_Form][populate]=*"
        );
        const data = await response.json();
        setApiContactUsData(          
          data.data[0]?.attributes.Contact_Us_Form[0] || {}
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Navbar />

      <div className="container contact-container pt-4 pb-5">
        <h3>
          {/* LET’S WRITE OUR GROWTH - STORIES <br />
          TOGETHER IN 2024 */}
          {apiContactUsData.Contact_Form_Heading}
        </h3>
        <div className="row contact-row pt-5">
          <div className="col-lg-6">
            <div className="contact-column">
              <h5>
              {apiContactUsData.Contact_Form_ContentText1} <br /> <b>{apiContactUsData.Contact_Form_ContentText2}</b> <br /> {apiContactUsData.Contact_Form_ContentText3}{" "}
                <br /> {apiContactUsData.Contact_Form_ContentText4}{" "}
                <b>
                {apiContactUsData.Contact_Form_ContentBoldText5} <br /> {apiContactUsData.Contact_Form_ContentBoldText6} <br /> {apiContactUsData.Contact_Form_ContentBoldText7}
                </b>{" "}
                {apiContactUsData.Contact_Form_ContentText8}
              </h5>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="contact-column">
              <form ref={form} onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="name">{apiContactUsData.Contact_Form_NameText}</label>
                  <input
                    type="text"
                    placeholder="Write your name"
                    className={`form-control ${errors.name && "is-invalid"}`}
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    maxLength="100"
                  />
                  
                  {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="email">{apiContactUsData.Contact_Form_EmailText}</label>
                  <input
                    type="email"
                    placeholder="Write your email id"
                    // className={`form-control ${errors.email && "is-invalid"}`}
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    maxLength="100"
                  />
                  {/* {errors.email && <div className="invalid-feedback">{errors.email}</div>} */}
                </div>
                <div className="mb-3 app-input">
                  <label htmlFor="phone">{apiContactUsData.Contact_Form_NumberText}</label>
                  <PhoneInput
                    international
                    defaultCountry="IN"                    
                    className={`form-control ${errors.phone && "is-invalid"}`}
                    value={formData.phone}
                    onChange={(value) => handleChange(value, "phone")} // Pass 'phone' as name
                    inputProps={{
                      id: "phone",
                      name: "phone",
                    }}
                    maxLength="20"
                  />
                  {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                </div>
                <div className="mb-5">
                  <label htmlFor="message">{apiContactUsData.Contact_Form_MessageText}</label>
                  <textarea
                    className={`form-control ${errors.message && "is-invalid"}`}
                    rows="6"
                    placeholder="Write a comment here"
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    maxLength="1000"
                  ></textarea>
                  {errors.message && <div className="invalid-feedback">{errors.message}</div>}
                </div>
                <button type="submit">
                {apiContactUsData.Contact_Form_SubmitText} <i className="bi bi-arrow-right-circle"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* <div className="row justify-content-center mt-5">
          <div className="col-lg-4 contact-page-btn">
            <button> <a href="tel:+919829864624">+91 - 98298 64624 </a></button>
            <button>
              <i className="bi bi-telephone-outbound"></i> CALL NOW /{" "}
              <i class="bi bi-whatsapp me-1"></i>WHATSAPP NOW{" "}
            </button>
          </div>
        </div> */}
      </div>

      <Footer />
    </>
  );
};

export default Contact;
