import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams hook
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';

const Jobdescription = () => {
  const { jobId } = useParams();
  const [jobDetails, setJobDetails] = useState(null);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await fetch(`https://api.youtubemarketingguy.com/api/we-are-hirings?populate=*`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        // const job = data.data.find(job => job.id === parseInt(jobId));
        const jobDetails = data.data[0].attributes.jobs.find(job => job.id == jobId);
        console.log("Job Details:", jobDetails);
        setJobDetails(jobDetails);
      } catch (error) {
        console.error("Error fetching job details:", error);
      }
    };

    fetchJobDetails();
  }, [jobId]);

  return (
    <>
      <Navbar />


      <div className="container-fluid description-box">
        <div className="container">
          {jobDetails && (
            <div className="row pt-4 pb-5">
              <div className="job-desc-heading mt-5">
                <h3>{jobDetails.jobs_title}</h3>
              </div>
              <div className="col-lg-10 pt-5 pb-4">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="desc-info">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/job.png`}
                        width="34"
                        height="26"
                        className="me-2"
                        alt=""
                      />
                      <h5>
                        Experience <br />
                        {jobDetails.jobs_exp}
                      </h5>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="desc-info">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/job1.png`}
                        width="34"
                        height="34"
                        className="me-2"
                        alt=""
                      />
                      <h5>
                        Job type <br />
                        {jobDetails.jobs_type}
                      </h5>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="desc-info">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/job2.png`}
                        width="31"
                        height="31"
                        className="me-2"
                        alt=""
                      />
                      <h5>
                        Mode<br />
                        {jobDetails.jobs_site}
                      </h5>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="desc-info">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/job3.png`}
                        width="29"
                        height="29"
                        className="me-2"
                        alt=""
                      />
                      <h5>
                        Location <br />
                        {jobDetails.jobs_location}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="container pt-5">
        <div className="row">
          {jobDetails && (
            <div className="col-lg-12">
              <div className="job-description-content">
                <h4>{jobDetails.About_the_company_heading}</h4>
                <p>
                  {jobDetails.About_the_company_text}

                </p>
              </div>
              <div className="job-description-content">
                <h4>{jobDetails.About_the_role_heading}</h4>
                <p>
                  {jobDetails.About_the_role_text}
                </p>
              </div>
              {/* <div className="job-description-content">
                    <h4>{jobDetails.Responsibilities_heading}</h4>
                    <p>
                   <li> {jobDetails.Responsibilities_ContentText1}</li>
                    <li>{jobDetails.Responsibilities_ContentText2}</li>
                    <li>{jobDetails.Responsibilities_ContentText3}</li>
                    <li>{jobDetails.Responsibilities_ContentText4}</li>
                    <li>{jobDetails.Responsibilities_ContentText5}</li>
                    <li>{jobDetails.Responsibilities_ContentText6}</li>
                  <li>{jobDetails.Responsibilities_ContentText7}</li>
                   <li> {jobDetails.Responsibilities_ContentText8}</li>
                    <li>{jobDetails.Responsibilities_ContentText9}</li>
                   <li> {jobDetails.Responsibilities_ContentText10}</li>
                    <li>{jobDetails.Responsibilities_ContentText11}</li>
                    <li>{jobDetails.Responsibilities_ContentText12}</li>
                    <li>{jobDetails.Responsibilities_ContentText13}</li>
                   <li> {jobDetails.Responsibilities_ContentText14}</li>
                    <li>{jobDetails.Responsibilities_ContentText15}</li>
                       
                        </p>
                  </div> */}
              <div className="job-description-content">
                <h4>{jobDetails.Responsibilities_heading}</h4>
                <ul>
                  {[...Array(15)].map((_, index) => (
                    jobDetails[`Responsibilities_ContentText${index + 1}`] && (
                      <li key={index}>
                        {`${jobDetails[`Responsibilities_ContentText${index + 1}`]}`}

                      </li>
                    )
                  ))}
                </ul>
              </div>

              <div className="job-description-content">
                <h4>{jobDetails.Requirements_heading}</h4>
                <p>
                  <ul>
                    {[...Array(15)].map((_, index) => (
                      jobDetails[`Requirements_ContentText${index + 1}`] && (
                        <li key={index}>
                          {`${jobDetails[`Requirements_ContentText${index + 1}`]}`}

                        </li>
                      )
                    ))}
                  </ul>
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="row justify-content-center pt-5 pb-5">
          {jobDetails && (
            <div className="col-lg-3">
              <div className="apply-btn">
                <Link to={`/applicationform/${jobDetails.id}`}>
                  <button onclick="location.href = 'application-form.html';"> Apply Now <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 42 42" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21 38.5C25.6413 38.5 30.0925 36.6563 33.3744 33.3744C36.6563 30.0925 38.5 25.6413 38.5 21C38.5 16.3587 36.6563 11.9075 33.3744 8.62563C30.0925 5.34374 25.6413 3.5 21 3.5C16.3587 3.5 11.9075 5.34375 8.62563 8.62563C5.34374 11.9075 3.5 16.3587 3.5 21C3.5 25.6413 5.34374 30.0925 8.62563 33.3744C11.9075 36.6563 16.3587 38.5 21 38.5ZM21 0.999998C26.3043 0.999998 31.3914 3.10713 35.1421 6.85786C38.8929 10.6086 41 15.6957 41 21C41 26.3043 38.8929 31.3914 35.1421 35.1421C31.3914 38.8929 26.3043 41 21 41C15.6957 41 10.6086 38.8929 6.85786 35.1421C3.10714 31.3914 0.999999 26.3043 0.999999 21C0.999999 15.6957 3.10714 10.6086 6.85786 6.85786C10.6086 3.10714 15.6957 0.999999 21 0.999998ZM12.25 19.75C11.9185 19.75 11.6005 19.8817 11.3661 20.1161C11.1317 20.3505 11 20.6685 11 21C11 21.3315 11.1317 21.6495 11.3661 21.8839C11.6005 22.1183 11.9185 22.25 12.25 22.25L26.7325 22.25L21.365 27.615C21.1303 27.8497 20.9984 28.1681 20.9984 28.5C20.9984 28.8319 21.1303 29.1503 21.365 29.385C21.5997 29.6197 21.9181 29.7516 22.25 29.7516C22.5819 29.7516 22.9003 29.6197 23.135 29.385L30.635 21.885C30.7514 21.7689 30.8438 21.6309 30.9068 21.4791C30.9698 21.3272 31.0022 21.1644 31.0022 21C31.0022 20.8356 30.9698 20.6728 30.9068 20.5209C30.8438 20.3691 30.7514 20.2311 30.635 20.115L23.135 12.615C22.9003 12.3803 22.5819 12.2484 22.25 12.2484C21.9181 12.2484 21.5997 12.3803 21.365 12.615C21.1303 12.8497 20.9984 13.1681 20.9984 13.5C20.9984 13.8319 21.1303 14.1503 21.365 14.385L26.7325 19.75L12.25 19.75Z" fill="black" stroke="black" />
                  </svg> </button>
                </Link>
              </div>
            </div>)}
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Jobdescription
