import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Career = () => {
const [apiHeadingData, setApiHeadingData] = useState([]);
const [apiHiringData, setApiHiringData] = useState([]); // Initialize as an array
const [currentPage, setCurrentPage] = useState(1);
const [searchQuery, setSearchQuery] = useState('');
const [selectedCategory, setSelectedCategory] = useState(null);
const [selectedExp, setSelectedExp] = useState(null);
const [selectedType, setSelectedType] = useState(null);
const [selectedSite, setSelectedSite] = useState(null);
const [selectedLocation, setSelectedLocation] = useState(null);

const jobsPerPage = 10;

useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/we-are-hirings?populate=*"
        );
        const data = await response.json();
        setApiHeadingData(data.data[0]?.attributes || {});
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://api.youtubemarketingguy.com/api/we-are-hirings?populate[jobs][populate]=*&pagination[pageSize]=100000"
      );
      const data = await response.json();
      setApiHiringData(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  fetchData();
}, []);

const handleCategorySelect = (category) => {
  setSelectedCategory(category);
  setCurrentPage(1);
  setSearchQuery('');
};
const handleExpSelect = (exp) => {
  setSelectedExp(exp);
  setCurrentPage(1);
  setSearchQuery('');
};
const handleTypeSelect = (type) => {
  setSelectedType(type);
  setCurrentPage(1);
  setSearchQuery('');
};
const handleSiteSelect = (site) => {
  setSelectedSite(site);
  setCurrentPage(1);
  setSearchQuery('');
};
const handleLocationSelect = (location) => {
  setSelectedLocation(location);
  setCurrentPage(1);
  setSearchQuery('');
};


const handleSearchChange = (event) => {
  const query = event.target.value.toLowerCase();
  setSearchQuery(query);
  setCurrentPage(1); // Reset page when search query changes
};

// Perform null check and type check before using flatMap
const filteredJobs = Array.isArray(apiHiringData) ? apiHiringData.flatMap((team) => team.attributes.jobs) : [];

// Filter based on selected filters and search query
const filteredAndSearchedJobs = filteredJobs.filter(job =>
  (!selectedCategory || job.jobs_title.toLowerCase() === selectedCategory.toLowerCase()) &&
  (!selectedExp || job.jobs_exp.toLowerCase() === selectedExp.toLowerCase()) &&
  (!selectedType || job.jobs_type.toLowerCase() === selectedType.toLowerCase()) &&
  (!selectedSite || job.jobs_site.toLowerCase() === selectedSite.toLowerCase()) &&
  (!selectedLocation || job.jobs_location.toLowerCase() === selectedLocation.toLowerCase()) &&
  (job.jobs_title.toLowerCase().includes(searchQuery.toLowerCase()) ||
  job.jobs_exp.toLowerCase().includes(searchQuery.toLowerCase()) ||
  job.jobs_type.toLowerCase().includes(searchQuery.toLowerCase()) ||
  job.jobs_site.toLowerCase().includes(searchQuery.toLowerCase()) ||
  job.jobs_location.toLowerCase().includes(searchQuery.toLowerCase()))
);

// Pagination
const indexOfLastJob = currentPage * jobsPerPage;
const indexOfFirstJob = indexOfLastJob - jobsPerPage;
const currentJobs = filteredAndSearchedJobs.slice(indexOfFirstJob, indexOfLastJob);

const handlePageChange = (pageNumber) => {
  setCurrentPage(pageNumber);
};

const handleAllClick = () => {
  setSelectedCategory(null); 
  setSelectedExp(null); 
  setSelectedType(null); 
  setSelectedSite(null); 
  setSelectedLocation(null); 
};

  return (
    <>
      <Navbar />

      <div className="container career-container">
        <div className="row career-first-row justify-content-center mt-4">
          <div className="col-lg-6">
            <h5>{apiHeadingData.Hiring_Heading}</h5>
            <p className="pt-3">{apiHeadingData.Hiring_Sub_Heading_text}</p>
          </div>
        </div>
        <div className="row justify-content-center pt-4 pb-5">
          <div className="col-lg-9">
            <div className="career-main-box">
            <div className="search-box">
                 <input
          type="text"
          placeholder="Search your job query"
          className="form-control"
          value={searchQuery}
          onChange={handleSearchChange}
        />
                <span className="bi bi-search field-icon"></span>
              </div>

              {apiHiringData.map((team) => (
                <div className="drop-filter pt-4" key={team.id}>
                  <div className="dropdown all-btn">
            <button onClick={handleAllClick}>All</button>
          </div>
                  <div className="dropdown">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownCategory"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {selectedCategory ? selectedCategory : 'Job Categories'}
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownCategory">
            {Array.from(new Set(apiHiringData.flatMap((team) => team.attributes.jobs.map((job) => job.jobs_title.toLowerCase())))).map((category) => (
              <li key={category}>
                <button
                  className="dropdown-item"
                  onClick={() => handleCategorySelect(category)}
                >
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className="dropdown">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownCategory"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {selectedType ? selectedType : 'Job Type'}
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownCategory">
            {Array.from(new Set(apiHiringData.flatMap((team) => team.attributes.jobs.map((job) => job.jobs_type.toLowerCase())))).map((type) => (
              <li key={type}>
                <button
                  className="dropdown-item"
                  onClick={() => handleTypeSelect(type)}
                >
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="dropdown">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownCategory"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {selectedSite ? selectedSite : 'On-site/remote'}
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownCategory">
            {Array.from(new Set(apiHiringData.flatMap((team) => team.attributes.jobs.map((job) => job.jobs_site.toLowerCase())))).map((site) => (
              <li key={site}>
                <button
                  className="dropdown-item"
                  onClick={() => handleSiteSelect(site)}
                >
                  {site.charAt(0).toUpperCase() + site.slice(1)}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="dropdown">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownCategory"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {selectedExp ? selectedExp : 'Experience level'}
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownCategory">
            {Array.from(new Set(apiHiringData.flatMap((team) => team.attributes.jobs.map((job) => job.jobs_exp.toLowerCase())))).map((exp) => (
              <li key={exp}>
                <button
                  className="dropdown-item"
                  onClick={() => handleExpSelect(exp)}
                >
                  {exp.charAt(0).toUpperCase() + exp.slice(1)}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="dropdown">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownCategory"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {selectedLocation ? selectedLocation : 'Location'}
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownCategory">
            {Array.from(new Set(apiHiringData.flatMap((team) => team.attributes.jobs.map((job) => job.jobs_location.toLowerCase())))).map((location) => (
              <li key={location}>
                <button
                  className="dropdown-item"
                  onClick={() => handleLocationSelect(location)}
                >
                  {location.charAt(0).toUpperCase() + location.slice(1)}
                </button>
              </li>
            ))}
          </ul>
        </div>
                </div>
              ))}
              <hr />

              {currentJobs.map((weHiring) => (
                <Link to={`/jobdescription/${weHiring.id}`} className="posting-box mt-5" key={weHiring.id}>
         
                  <div className="job-info">
                    <h4>{weHiring.jobs_title}</h4>
                    <p className="pt-2">
                      <img src="images/job.png" width="14" height="14" alt="" />{" "}
                      {weHiring.jobs_exp}{" "}
                    </p>
                    <p className="pt-2">
                      <img
                        src="images/job1.png"
                        width="23"
                        height="23"
                        alt=""
                      />
                      {weHiring.jobs_type}{" "}
                    </p>
                    <div className="d-flex align-items-center pt-2">
                      <p className="me-4">
                        <img
                          src="images/job2.png"
                          width="25"
                          height="25"
                          alt=""
                        />{" "}
                        {weHiring.jobs_site}{" "}
                      </p>
                      <p>
                        {" "}
                        <img
                          src="images/job3.png"
                          width="17"
                          height="17"
                          alt=""
                        />{" "}
                        {weHiring.jobs_location}
                      </p>
                    </div>
                  </div>
                  <div className="job-more">
                    <h6>
                      Know more{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle cx="12" cy="12" r="12" fill="#F5C518" />
                        <path
                          d="M18.7071 12.7071C19.0976 12.3166 19.0976 11.6834 18.7071 11.2929L12.3431 4.92893C11.9526 4.53841 11.3195 4.53841 10.9289 4.92893C10.5384 5.31946 10.5384 5.95262 10.9289 6.34315L16.5858 12L10.9289 17.6569C10.5384 18.0474 10.5384 18.6805 10.9289 19.0711C11.3195 19.4616 11.9526 19.4616 12.3431 19.0711L18.7071 12.7071ZM5 13H18V11H5V13Z"
                          fill="black"
                        />
                      </svg>{" "}
                    </h6>
                  </div>
                </Link>
              ))}
              
             
              {/* {currentJobs.map((job) => (
  <Link to={`/jobdescription/${job.id}`} className="posting-box mt-5" key={job.id}>
    <div className="job-info">
      <h4>{job.jobs_title}</h4>
      <p className="pt-2">
        {job.Experience_Icon && job.Experience_Icon.data && job.Experience_Icon.data[0] &&
           <img src={`https://api.youtubemarketingguy.com${job.Experience_Icon.data[0].attributes.url}`}  width="14" height="14" alt="" />
        }{" "}{" "}
        Experience - {job.jobs_exp}
      </p>
      <p className="pt-2">
        {job.Jobs_Type_Icon && job.Jobs_Type_Icon.data && job.Jobs_Type_Icon.data[0] &&
          <img src={`https://api.youtubemarketingguy.com${job.Jobs_Type_Icon.data[0].attributes.url}`} width="23" height="23" alt="" />
        }{" "}{" "}
        {job.jobs_type}
      </p>
      <div className="d-flex align-items-center pt-2">
        <p className="me-4">
          {job.Jobs_Site_Icon && job.Jobs_Site_Icon.data && job.Jobs_Site_Icon.data[0] &&
            <img src={`https://api.youtubemarketingguy.com${job.Jobs_Site_Icon.data[0].attributes.url}`} width="25" height="25" alt="" />
          }{" "}{" "}
          {job.jobs_site}
        </p>
        <p>
        {job.Jobs_Site_Icon && job.Jobs_Location_Icon.data && job.Jobs_Location_Icon.data[0] &&
            <img src={`https://api.youtubemarketingguy.com${job.Jobs_Location_Icon.data[0].attributes.url}`} width="17" height="17" alt="" />
          }{" "}{" "}
          {job.jobs_location}
        </p>
      </div>
    </div>
    <div className="job-more">
      <h6>
        Know more {" "}
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <circle cx="12" cy="12" r="12" fill="#F5C518" />
          <path d="M18.7071 12.7071C19.0976 12.3166 19.0976 11.6834 18.7071 11.2929L12.3431 4.92893C11.9526 4.53841 11.3195 4.53841 10.9289 4.92893C10.5384 5.31946 10.5384 5.95262 10.9289 6.34315L16.5858 12L10.9289 17.6569C10.5384 18.0474 10.5384 18.6805 10.9289 19.0711C11.3195 19.4616 11.9526 19.4616 12.3431 19.0711L18.7071 12.7071ZM5 13H18V11H5V13Z" fill="black" />
        </svg>
      </h6>
    </div>
  </Link>
))} */}

               {/* Pagination */}
        <div className="pagination">
          <div className="pages">
            {Array.from({ length: Math.ceil(filteredJobs.length / jobsPerPage) }, (_, index) => (
              <div
                key={index}
                className={`page-number ${currentPage === index + 1 ? 'page-active' : ''}`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </div>
            ))}
          </div>
        </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Career;
