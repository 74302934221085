import React, { useState, useEffect } from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";

const ThankYouPage = () => {
  const [apiContactSubmitData, setApiContactSubmitData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/we-are-hirings?populate[JobForm_ThankPage][populate]=*"
        );
        const data = await response.json();
        setApiContactSubmitData(
          data.data[0]?.attributes.JobForm_ThankPage[0] || {}
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Navbar />

      <div class="container pb-5">
        <div class="row justify-content-center pt-5">
          <div class="col-lg-9">
            <div class="thankyou-box">
              {/* <img src="images/thanks.png" alt="" /> */}
              {apiContactSubmitData.Thank_Image && (
                  <img
                    src={`https://api.youtubemarketingguy.com${apiContactSubmitData.Thank_Image.data[0].attributes.url}`}
                    alt="Image not found"
                  />
                )}
              <h3 class="pt-5">{apiContactSubmitData.Thank_Text1}</h3>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ThankYouPage;
