import React, { useEffect, useState, useRef} from "react";
import emailjs from '@emailjs/browser'
import { useParams ,useNavigate } from 'react-router-dom'; 
import Footer from "./Footer";
import PhoneInput from "react-phone-number-input";
import validator from "validator";
import Navbar from './Navbar';

const Applicationform = () => {
//   const form = useRef();
//   const navigate = useNavigate();
//   const fileInputRef = useRef(null);
//   const [apiContactUsData, setApiContactUsData] = useState({});
 
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [formData, setFormData] = useState({
//     fname: "",
//     email: "",
//     mobile: "",
//     exp: "",
//     company_name: "",
//     p_a_ctc: "",
//     notice_p_d: "",
//     link: "",
//     doc: "",
//     job_profile: "",
//     job_location: "",
//     job_experience: "",
//     job_type: "",
//     job_mode: "",
//   });
//   const [errors, setErrors] = useState({});
//   const { jobId } = useParams();
//   const [jobDetails, setJobDetails] = useState(null);


//   useEffect(() => {
//     const fetchJobDetails = async () => {
//       try {
//         const response = await fetch(`https://api.youtubemarketingguy.com/api/we-are-hirings?populate=*`);
//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }
//         const data = await response.json();
//         const jobDetails = data.data[0].attributes.jobs.find(job => job.id == jobId);
//         setJobDetails(jobDetails);
//       } catch (error) {
//         console.error("Error fetching job details:", error);
//       }
//     };

//     fetchJobDetails();
//   }, [jobId]);

//   const handleChange = (value, name) => {
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));

//     // Clear mobile number error when user starts typing
//     if (name === "mobile" && errors.mobile) {
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         mobile: "",
//       }));
//     }
//   };

//   const handleFileChange = (e) => {
//     setSelectedFile(e.target.files[0]);
//   };

//   const sendEmail = () => {
//     // Send email to client
    // emailjs.sendForm(
    //   "service_2jajma6",
    //   "template_yr00vjs",
    //   form.current,
    //   "Zz8P39egmRcdrIvhE"
    // )
//     .then(
//       (result) => {
//         console.log(result.text);
//         console.log('Client email sent successfully');
//       },
//       (error) => {
//         console.error(error.text);
//         console.error('Failed to send client email');
//       }
//     );
// };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const combinedData = {
//       ...formData,
//       job_profile: jobDetails ? jobDetails.jobs_title : "",
//       job_location: jobDetails ? jobDetails.jobs_location : "",
//       job_experience: jobDetails ? jobDetails.jobs_exp : "",
//       job_type: jobDetails ? jobDetails.jobs_type : "",
//       job_mode: jobDetails ? jobDetails.jobs_site : "",
//     };

//     const validationErrors = {};
//     if (!combinedData.fname) {
//       validationErrors.fname = "Full name is required";
//     }
//     if (!combinedData.email) {
//       validationErrors.email = "Email is required";
//     }
//     if (!combinedData.mobile) {
//       validationErrors.mobile = "Mobile number is required";
//     } else if (!validator.isMobilePhone(combinedData.mobile)) {
//       validationErrors.mobile = "Invalid mobile number";
//     }
//     if (!combinedData.link) {
//       validationErrors.link = "Behance or portfolio link is required";
//     }
//     // if (!selectedFile) {
//     //   validationErrors.doc = "Please select your resume in PDF or DOC format";
//     // } else {
//     //   const allowedFileTypes = [
//     //     "application/pdf",
//     //     "application/msword",
//     //     "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
//     //   ];
//     //   if (!allowedFileTypes.includes(selectedFile.type)) {
//     //     validationErrors.doc = "Invalid file type. Please select a PDF or DOC file";
//     //   } else if (selectedFile.size > 2 * 1024 * 1024) {
//     //     validationErrors.doc = "File size exceeds 2 MB. Please select a file with a size less than 2 MB";
//     //   }
//     // }

//     if (!selectedFile) {
//       setErrors({ ...errors, doc: "Please select your resume" });
//       return;
//     }
  
//     const fileSizeInBytes = selectedFile.size;
//     const maxSizeInBytes = 10 * 1024 * 1024; // 2 MB
//     if (fileSizeInBytes > maxSizeInBytes) {
//       setErrors({
//         ...errors,
//         doc: "File size exceeds 2 MB. Please select a smaller file.",
//       });
//       return;
//     }

//     setErrors(validationErrors);

//     if (Object.keys(validationErrors).length > 0) {
//       return;
//     }

//     try {
//       const formData = new FormData();
//       formData.append("files", selectedFile);

//       const uploadResponse = await fetch("https://api.youtubemarketingguy.com/api/upload", {
//         method: "POST",
//         body: formData,
//       });

//       if (!uploadResponse.ok) {
//         console.error("File upload failed:", uploadResponse.statusText);
//         return;
//       }

//       const uploadData = await uploadResponse.json();
//       combinedData.doc = uploadData.url;

//       const response = await fetch("https://api.youtubemarketingguy.com/api/jobs-forms", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           data: combinedData,
//         }),
//       });

//       if (response.ok) {
//         console.log("Form submitted successfully!");
//         sendEmail();  // Send email after successful form submission
//         navigate("/thank");
//       } else {
//         console.error("Form submission failed:", response.status);
//       }
//     } catch (error) {
//       console.error("Error submitting form:", error);
//     }
//   };


const form = useRef();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [apiContactUsData, setApiContactUsData] = useState({});
 
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    fname: "",
    email: "",
    mobile: "",
    exp: "",
    company_name: "",
    p_a_ctc: "",
    notice_p_d: "",
    link: "",
    doc: "",
    job_profile: "",
    job_location: "",
    job_experience: "",
    job_type: "",
    job_mode: "",
  });
  const [errors, setErrors] = useState({});
  const { jobId } = useParams();
  const [jobDetails, setJobDetails] = useState(null);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await fetch(`https://api.youtubemarketingguy.com/api/we-are-hirings?populate=*`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        const jobDetails = data.data[0].attributes.jobs.find(job => job.id == jobId);
        setJobDetails(jobDetails);
      } catch (error) {
        console.error("Error fetching job details:", error);
      }
    };

    fetchJobDetails();
  }, [jobId]);

  const handleChange = (value, name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Clear mobile number error when user starts typing
    if (name === "mobile" && errors.mobile) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: "",
      }));
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const combinedData = {
  //     ...formData,
  //     job_profile: jobDetails ? jobDetails.jobs_title : "",
  //     job_location: jobDetails ? jobDetails.jobs_location : "",
  //     job_experience: jobDetails ? jobDetails.jobs_exp : "",
  //     job_type: jobDetails ? jobDetails.jobs_type : "",
  //     job_mode: jobDetails ? jobDetails.jobs_site : "",
  //   };

  //   const validationErrors = {};
  //   if (!combinedData.fname) {
  //     validationErrors.fname = "Full name is required";
  //   }
  //   if (!combinedData.email) {
  //     validationErrors.email = "Email is required";
  //   }
  //   if (!combinedData.mobile) {
  //     validationErrors.mobile = "Mobile number is required";
  //   } else if (!validator.isMobilePhone(combinedData.mobile)) {
  //     validationErrors.mobile = "Invalid mobile number";
  //   }
  //   if (!combinedData.link) {
  //     validationErrors.link = "Behance or portfolio link is required";
  //   }

  //   if (!selectedFile) {
  //     setErrors({ ...errors, doc: "Please select your resume" });
  //     return;
  //   }
  
  //   const fileSizeInBytes = selectedFile.size;
  //   const maxSizeInBytes = 10 * 1024 * 1024; // 2 MB
  //   if (fileSizeInBytes > maxSizeInBytes) {
  //     setErrors({
  //       ...errors,
  //       doc: "File size exceeds 2 MB. Please select a smaller file.",
  //     });
  //     return;
  //   }

  //   setErrors(validationErrors);

  //   if (Object.keys(validationErrors).length > 0) {
  //     return;
  //   }

  //   try {
  //     const formData = new FormData();
  //     formData.append("files", selectedFile);

  //     const uploadResponse = await fetch("https://api.youtubemarketingguy.com/api/upload", {
  //       method: "POST",
  //       body: formData,
  //     });

  //     if (!uploadResponse.ok) {
  //       console.error("File upload failed:", uploadResponse.statusText);
  //       return;
  //     }

  //     const uploadData = await uploadResponse.json();
  //     combinedData.doc = uploadData.url;

  //     const response = await fetch("https://api.youtubemarketingguy.com/api/jobs-forms", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         data: combinedData,
  //       }),
  //     });

  //     if (response.ok) {
  //       console.log("Form submitted successfully!");

  //       // Call sendEmail function after successful form submission
  //       sendEmail();

  //       navigate("/thank");
  //     } else {
  //       console.error("Form submission failed:", response.status);
  //     }
  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //   }
  // };

  // const sendEmail = () => {
  //   // Send email to client
  //   emailjs.sendForm(
  //     "service_2jajma6",
  //     "template_yr00vjs",
  //     form.current,
  //     "Zz8P39egmRcdrIvhE"
  //   )
  //   .then(
  //     (result) => {
  //       console.log(result.text);
  //       console.log('Client email sent successfully');
  //     },
  //     (error) => {
  //       console.error(error.text);
  //       console.error('Failed to send client email');
  //     }
  //   );
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const combinedData = {
      ...formData,
      job_profile: jobDetails ? jobDetails.jobs_title : "",
      job_location: jobDetails ? jobDetails.jobs_location : "",
      job_experience: jobDetails ? jobDetails.jobs_exp : "",
      job_type: jobDetails ? jobDetails.jobs_type : "",
      job_mode: jobDetails ? jobDetails.jobs_site : "",
    };
  
    const validationErrors = {};
    if (!combinedData.fname) {
      validationErrors.fname = "Full name is required";
    }
    if (!combinedData.email) {
      validationErrors.email = "Email is required";
    }
    if (!combinedData.mobile) {
      validationErrors.mobile = "Mobile number is required";
    } else if (!validator.isMobilePhone(combinedData.mobile)) {
      validationErrors.mobile = "Invalid mobile number";
    }
    if (!combinedData.link) {
      validationErrors.link = "Behance or portfolio link is required";
    }
  
    if (!selectedFile) {
      setErrors({ ...errors, doc: "Please select your resume" });
      return;
    }
  
    const fileSizeInBytes = selectedFile.size;
    const maxSizeInBytes = 10 * 1024 * 1024; // 2 MB
    if (fileSizeInBytes > maxSizeInBytes) {
      setErrors({
        ...errors,
        doc: "File size exceeds 10 MB. Please select a smaller file.",
      });
      return;
    }
  
    setErrors(validationErrors);
  
    if (Object.keys(validationErrors).length > 0) {
      return;
    }
  
    try {
      const formData = new FormData();
      formData.append("files", selectedFile);
  
      const uploadResponse = await fetch("https://api.youtubemarketingguy.com/api/upload", {
        method: "POST",
        body: formData,
      });
  
      if (!uploadResponse.ok) {
        console.error("File upload failed:", uploadResponse.statusText);
        return;
      }
  
      const uploadData = await uploadResponse.json();
      combinedData.doc = uploadData.url;
  
      // After successful file upload, submit form data and then send email
      await submitFormData(combinedData);
      sendEmail();
  
      navigate("/thank");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  
  const submitFormData = async (combinedData) => {
    try {
      const response = await fetch("https://api.youtubemarketingguy.com/api/jobs-forms", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: combinedData,
        }),
      });
  
      if (!response.ok) {
        console.error("Form submission failed:", response.status);
      } else {
        console.log("Form submitted successfully!");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  
  const sendEmail = () => {
    // Send email to client
    emailjs.sendForm(
      "service_2jajma6",
      "template_yr00vjs",
      form.current,
      "Zz8P39egmRcdrIvhE"
    )
    .then(
      (result) => {
        console.log(result.text);
        console.log('Client email sent successfully');
      },
      (error) => {
        console.error(error.text);
        console.error('Failed to send client email');
      }
    );
  };
  
  
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/we-are-hirings?populate[JobFormContent][populate]=*"
        );
        const data = await response.json();
        setApiContactUsData(          
          data.data[0]?.attributes.JobFormContent[0] || {}
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []);

  return (
    <>
      <Navbar />

      <div className="container-fluid description-box">
        <div className="container"> {jobDetails && (
          <div className="row pt-4 pb-5">
            <div className="job-desc-heading mt-5">
              <h3>{jobDetails.jobs_title}</h3>
            </div>
            <div className="col-lg-10 pt-5 pb-4">
              <div className="row">
                <div className="col-lg-3">
                  <div className="desc-info">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/job.png`}
                      width="34"
                      height="26"
                      className="me-2"
                      alt=""
                    />
                    <h5>
                      Experience  <br /> {jobDetails.jobs_exp}
                    </h5>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="desc-info">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/job1.png`}
                      width="34"
                      height="34"
                      className="me-2"
                      alt=""
                    />
                    <h5>
                      Job type <br />
                      {jobDetails.jobs_type}
                    </h5>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="desc-info">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/job2.png`}
                      width="31"
                      height="31"
                      className="me-2"
                      alt=""
                    />
                    <h5>
                      Mode <br />
                      {jobDetails.jobs_site}
                    </h5>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="desc-info">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/job3.png`}
                      width="29"
                      height="29"
                      className="me-2"
                      alt=""
                    />
                    <h5>
                      Location  <br />
                      {jobDetails.jobs_location}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        </div>
      </div>

      <div className="container pt-5 pb-5">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="application-form">
              <h4 className="pt-4">{apiContactUsData.JobForm_Heading}</h4>
              <form className="pt-5" ref={form} onSubmit={handleSubmit}>

                {jobDetails && (
                  <>
                    <input
                      type="text"
                      name="job_profile"
                      value={jobDetails.jobs_title}
                      onChange={(e) => handleChange(e.target.value, e.target.name)}
                      maxLength="100"
                      hidden
                    />

                    <input
                      type="text"
                      name="job_localtion"
                      value={jobDetails.jobs_location}
                      onChange={(e) => handleChange(e.target.value, e.target.name)}
                      maxLength="100"
                      hidden
                    />

                    <input
                      type="text"
                      name="job_experience"
                      value={jobDetails.jobs_exp}
                      onChange={(e) => handleChange(e.target.value, e.target.name)}
                      maxLength="100"
                      hidden
                    />

                    <input
                      type="text"
                      name="job_type"
                      value={jobDetails.jobs_type}
                      onChange={(e) => handleChange(e.target.value, e.target.name)}
                      maxLength="100"
                      hidden
                    />

                    <input
                      type="text"
                      name="job_mode"
                      value={jobDetails.jobs_site}
                      onChange={(e) => handleChange(e.target.value, e.target.name)}
                      maxLength="100"
                      hidden
                    />
                  </>
                )}


                {/* Full name */}
                <div className="mb-4">
                  <label className="mb-1">{apiContactUsData.JobForm_TextName}*</label>
                  <input
                    type="text"
                    className={`form-control ${errors.fname && "is-invalid"}`}
                    placeholder="Write your name"
                    name="fname"
                    value={formData.fname}
                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                    maxLength="100"
                  />
                  {errors.fname && <div className="invalid-feedback">{errors.fname}</div>}
                </div>

                {/* Email */}
                <div className="mb-4">
                  <label className="mb-1">{apiContactUsData.JobForm_TextEmail}</label>
                  <input
                    type="email"
                    className={`form-control ${errors.email && "is-invalid"}`}
                    placeholder="Write your email ID"
                    name="email"
                    value={formData.email}
                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                    maxLength="100"
                  />
                  {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                </div>

                {/* Mobile number */}
                <div className="mb-4 app-input">
                  <label className="mb-1">{apiContactUsData.JobForm_TextMoblie}*</label>
                  <PhoneInput
                    international
                    defaultCountry="IN"

                    className={`form-control ${errors.mobile && "is-invalid"}`}

                    value={formData.mobile}
                    onChange={(value) => handleChange(value, "mobile")} // Pass 'phone' as name
                    inputProps={{
                      name: "mobile",
                    }}
                    maxLength="20"
                  />
                  {errors.mobile && <div className="invalid-feedback">{errors.mobile}</div>}
                </div>

                <div className="mb-4">
                  <label className="mb-1">
                  {apiContactUsData.JobForm_TextTotalExperience}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Write your total experience"
                    id="exp"
                    name="exp"
                    value={formData.exp}
                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                    maxLength="2"
                    onKeyPress={(e) => {
                      // Allow only numbers
                      const keyCode = e.keyCode || e.which;
                      const keyValue = String.fromCharCode(keyCode);
                      if (!/^\d+$/.test(keyValue)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>

                <div className="mb-4">
                  <label className="mb-1">{apiContactUsData.JobForm_TextExperienceCompanyName} </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Write your experience company name "
                    id="company_name"
                    name="company_name"
                    value={formData.company_name}
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    maxLength="100"
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-1">{apiContactUsData.JobForm_TextPreviousCTC}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Write your previous annual CTC"
                    id="p_a_ctc"
                    name="p_a_ctc"
                    value={formData.p_a_ctc}
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    maxLength="10"
                    onKeyPress={(e) => {
                      // Allow only numbers
                      const keyCode = e.keyCode || e.which;
                      const keyValue = String.fromCharCode(keyCode);
                      if (!/^\d+$/.test(keyValue)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-1">{apiContactUsData.JobForm_TextNoticePeriod}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Write your notice period (days)"
                    id="notice_p_d"
                    name="notice_p_d"
                    value={formData.notice_p_d}
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    maxLength="5"
                    onKeyPress={(e) => {
                      // Allow only numbers
                      const keyCode = e.keyCode || e.which;
                      const keyValue = String.fromCharCode(keyCode);
                      if (!/^\d+$/.test(keyValue)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>

                <div className="mb-4">
                  <label className="mb-1">{apiContactUsData.JobForm_TextportfolioLink}*</label>
                  <input
                    type="text"
                    className={`form-control ${errors.link && "is-invalid"}`}
                    placeholder="Write your behance or portfolio link"
                    id="link"
                    name="link"
                    value={formData.link}
                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                    maxLength="500"
                  />
                  {errors.link && <div className="invalid-feedback">{errors.link}</div>}
                </div>

      <div className="mb-4">
  <label className="mb-1">{apiContactUsData.JobForm_TextUploadResume}*</label>
  <br />
  <small>DOC, DOCx, PDF, RTF | Max: 10 MB</small>
  <div className={`${errors.doc && "is-invalid"}`}>
  <input
                    type="file"
                    className={`form-control mt-2`}
                    placeholder="Write your behance or portfolio link"
                    name="doc" onChange={handleFileChange}
                  /></div>
                  {errors.doc && <div className="invalid-feedback">{errors.doc}</div>} 
  {/* <div className={`drag-area mt-4 ${errors.doc && "is-invalid"}`}>
    <div className="icon">
      <img src={`${process.env.PUBLIC_URL}/images/file.png`} width="28" height="22" alt="" />
    </div>
    <header>Drag and Drop files here</header>
    <span>OR</span>
    <button type="button" onClick={() => fileInputRef.current.click()}>Browse File</button>
    <input type="file" ref={fileInputRef} name="doc" onChange={handleFileChange} style={{ display: "none" }} />
  </div>
  {errors.doc && <div className="invalid-feedback">{errors.doc}</div>} */}
  {/* <input type="file" className="form-control" id="myfile" name="myfile"></input> */}
</div>


                <div className="submit-btn pt-4 pb-4">
                  <button type="submit" className="submit-btn pt-4 pb-4">
                  {apiContactUsData.JobForm_TextSubmit}
                  </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Applicationform;
