import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Blog = () => {

    return (
        <>
          <Navbar />

<div className="Container">
    <div className="row justify-content-center">
        <div className="col-lg-6">
            <div className="blog-column">
                <img src="images/blog.png" alt="blog-img"></img>
                <h6>Something Great is on the way</h6>
                <h3>Coming Soon</h3>
                <button>Back to Homepage</button>
            </div>
        </div>
    </div>
</div>

          <Footer />
    </>
  );
};

export default Blog;