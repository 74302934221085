import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const Footer = () => {
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/footers?populate=*"
        );
        const data = await response.json();
        setApiData(data.data[0]?.attributes || {});
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="container-fluid footer-container">
        <div className="container pt-5 pb-5">
          <div className="row footer-row">
            <div className="col-lg-3">
              <div className="footer-column">
                <h4>{apiData.contact_us}</h4>
                
                <li>
                  {" "}
                  <Link to="mailto:hello@cocomadigital.com">
                    <i className="bi bi-envelope"></i> &nbsp; {apiData.email}
                  </Link>{" "}
                </li>
                {/* <li> <Link to="mail"><i className="bi bi-telephone"></i> &nbsp; +91 8655986192</Link> </li> */}
                <li>
                  <a href="tel:+918655986192">
                    <i className="bi bi-telephone"></i> &nbsp; {apiData.phone}
                  </a>
                </li>
                <li>
                  <Link to="">{apiData.privacy_policy}</Link>
                </li>
                <li>
                  <Link to="">{apiData.term_and_condition}</Link>
                </li>
                <li>
                  <Link to="">{apiData.reference_cancellations}</Link>
                </li>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-column">
                <h4>{apiData.our_office}</h4>
                <div className="d-flex">
                  <i className="bi bi-geo-alt-fill me-2"></i>
                  <p>{apiData.office_address1}</p>
                </div>
                <div className="d-flex">
                  <i className="bi bi-geo-alt-fill me-2"></i>
                  <p>{apiData.office_address2}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="social-icons">
                <Link
                  to="https://www.youtube.com/@ForBrandChannels"
                  target="_blank"
                >
                  {/* <img src="images/foot.png" alt="" /> */}
                  <img src={`${process.env.PUBLIC_URL}/images/foot.png`} alt="" />
                </Link>
                <Link
                  to="https://www.instagram.com/cocomadigital/"
                  target="_blank"
                >
                 {/* <img src="images/foot1.png" alt="" /> */}
                 <img src={`${process.env.PUBLIC_URL}/images/foot1.png`} alt="" />
                </Link>
                <Link
                  to="https://www.linkedin.com/company/cocomadigital/"
                  target="_blank"
                >
                  {/* <img src="images/foot2.png" alt="" /> */}
                  <img src={`${process.env.PUBLIC_URL}/images/foot2.png`} alt="" />
                </Link>
                <Link
                  to="https://www.facebook.com/cocomadigital/"
                  target="_blank"
                >
                  {/* <img src="images/foot3.png" alt="" /> */}
                  <img src={`${process.env.PUBLIC_URL}/images/foot3.png`} alt="" />
                </Link>

                <div>
                  <p>{apiData.copy_right_content}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
