import React, { useEffect, useState, useRef} from "react";
import emailjs from '@emailjs/browser'
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import validator from "validator"; // Import validator library for additional validation
import { useHistory } from 'react-router-dom';

const Services = () => {
  // const history = useHistory();
  const navigate = useNavigate();
  const form = useRef();
  const [apiContactUsData, setApiContactUsData] = useState({});
  const [apiTrustedByTheBest, setApiTrustedByTheBest] =
    useState([]);
  const [apiAccelerateData, setApiAccelerateData] = useState([]);
  const [apiHomeBanner, setApiHomeBanner] = useState([]);
  
  const [apiHeadingData, setApiHeadingData] = useState([]);
  const [apiServicesData, setApiServicesData] = useState([]);
  const [selectedService, setSelectedService] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({}); // State to store validation errors


  const handleChange = (value, name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Clear mobile number error when user starts typing
    if (name === "mobile" && errors.mobile) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: "",
      }));
    }
  };

  const sendEmail = () => {
    // Send email to client
    emailjs.sendForm(
      "service_2jajma6",
      "template_kii0cau",
      form.current,
      "Zz8P39egmRcdrIvhE"
    )
    .then(
      (result) => {
        console.log(result.text);
        console.log('Client email sent successfully');
      },
      (error) => {
        console.error(error.text);
        console.error('Failed to send client email');
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform validation
    const validationErrors = {};
    if (!formData.name) {
      validationErrors.name = "Full name is required";
    }
    if (!formData.email) {
      validationErrors.email = "Email is required";
    }
    if (!formData.phone) {
      validationErrors.phone = "Mobile number is required";
    } else if (!validator.isMobilePhone(formData.phone)) {
      validationErrors.phone = "Invalid mobile number";
    }
    if (!formData.message) {
      validationErrors.message = "Message is required";
    }

     // Set validation errors
     setErrors(validationErrors);

     // If there are validation errors, prevent form submission
     if (Object.keys(validationErrors).length > 0) {
       return;
     }

    try {
      const response = await fetch(
        "https://api.youtubemarketingguy.com/api/form-submits",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: formData,
          }),
        }
      );

      if (response.ok) {
        console.log("Form submitted successfully!");
        sendEmail(); 
        navigate("/contactsubmit");
        // history.push('/thank');
      } else {
        console.error("Form submission failed:", response.status);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/services?populate[Service_Component][populate]=*"
        );
        const data = await response.json();
        setApiServicesData(data.data);

        // Set the first service as selected by default
        if (data.data.length > 0 && !selectedService) {
          setSelectedService(data.data[0].attributes.Service_Component[0].id);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedService]);

  const handleServiceClick = (id) => {
    setSelectedService(id);
  };

  const handleExploreClick = (teamId) => {
    setSelectedService(teamId);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/services?populate"
        );
        const data = await response.json();
        setApiHeadingData(
          data.data[0]?.attributes || {}
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/services?populate[Accelerate_Growth][populate]=*"
        );
        const data = await response.json();
        setApiAccelerateData(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/services?populate[Service_Banner][populate]=*"
        );
        const data = await response.json();
        setApiHomeBanner(
          data.data[0]?.attributes.Service_Banner[0] || {});
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/services?populate[Service_TrustedByTheBest][populate]=*"
        );
        const data = await response.json();
        setApiTrustedByTheBest(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/desktop-contact-uses?populate[Contact_Us_Form][populate]=*"
        );
        const data = await response.json();
        setApiContactUsData(          
          data.data[0]?.attributes.Contact_Us_Form[0] || {}
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Navbar />

      <div className="container-fluid main-service-banner">
        <div className="container">
            <div className="row service-banner ">
              <div className="col-lg-7 pt-5 pb-5">
                <h3>{apiHomeBanner.Banner_Text1}</h3>
                <h3>{apiHomeBanner.Banner_Text2}</h3>
              </div>
              <div className="col-lg-5 right-ser-column pb-5">
                <div className="img-side-ban">
                {apiHomeBanner.Banner_Image && (
                <img
                  src={`https://api.youtubemarketingguy.com${apiHomeBanner.Banner_Image.data[0].attributes.url}`}
                  alt="Image not found"
                />
              )}
                </div>
              </div>
            </div>
        </div>
      </div>

      {/* TRUSTED_BY_THE_BEST */}

      <div className="container pt-5 pb-4">
        <div className="row trust-row">
          <h5>{apiHeadingData.TrustedByTheBest_Heading}</h5>
          <div className="marquee mt-5">
            <div className="marquee-content">
              {apiTrustedByTheBest.map((item) =>
                item.attributes.Service_TrustedByTheBest.map(
                  (trustedItem) =>
                    trustedItem.Images &&
                    trustedItem.Images.data.map(
                      (image, imageIndex) => (
                        <div className="marquee-item" key={imageIndex}>
                          <img
                            src={`https://api.youtubemarketingguy.com${image.attributes.url}`}
                            alt={`Image ${imageIndex + 1}`}
                          />
                        </div>
                      )
                    )
                )
              )}
            </div>
          </div>
        </div>
      </div>

      {/* with effecct section  */}
      {/* OUR SERVICES */}

      <div className="container service-container pt-5">
        <h3>{apiHeadingData.OurService_Heading}</h3>
        <div className="row pt-4">
          <div className="col-lg-7 pt-3">
            <div className="tab-content">
              {apiServicesData.map((team) =>
                team.attributes.Service_Component.map((servicesIteam) => (
                  <div
                    key={servicesIteam.id}
                    className={`tab-pane fade ${
                      selectedService === servicesIteam.id ? "show active" : ""
                    }`}
                    id={`service-content-${servicesIteam.id}`}
                    role="tabpanel"
                    aria-labelledby={`service-${servicesIteam.id}-tab`}
                  >
                    <div className="left-box">
                      <div className="left-img-box">
                        {servicesIteam.service_content_image &&
                          servicesIteam.service_content_image.data &&
                          servicesIteam.service_content_image.data.map(
                            (service_content_image, imageIndex) => (
                              <img
                                key={imageIndex}
                                src={`https://api.youtubemarketingguy.com${service_content_image.attributes.url}`}
                                alt={service_content_image.attributes.name}
                              />
                            )
                          )}
                      </div>
                      <div className="left-content-box">
                        <h4 className="pt-4">
                          {servicesIteam.service_content_heading}{" "}
                        </h4>
                        <p className="pt-4 mt-3">
                          {servicesIteam.service_content_text}
                        </p>

                        <Link to={`/singleservice/${servicesIteam.id}`}>
                          {" "}
                          <button
                            className="mt-4 mb-4"
                            onClick={() => handleExploreClick(servicesIteam.id)}
                          >
                            {servicesIteam.service_text_explore}{" "}
                            <i className="bi bi-arrow-right"></i>
                          </button>{" "}
                        </Link>
                        <div className="left-bottom-box mt-5">
                          <img src="images/325%.png" alt="" className="me-2" />
                          <h6>{servicesIteam.service_text_footer}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="col-lg-5">
            <div
              className="nav row nav-pills me-3"
              id="v-pills-tab"
              role="tablist"
            >
              {apiServicesData.map((team) =>
                team.attributes.Service_Component.map((servicesIteam) => (
                  <div
                    key={servicesIteam.id}
                    className="col-lg-6"
                    role="tab"
                    aria-controls={`service-content-${servicesIteam.id}`}
                  >
                    <div
                      className={`service-box right-box ${
                        selectedService === servicesIteam.id ? "active" : ""
                      }`}
                      onClick={() => handleServiceClick(servicesIteam.id)}
                    >
                      {servicesIteam.service_image &&
                        servicesIteam.service_image.data &&
                        servicesIteam.service_image.data.map(
                          (service_image, imageIndex) => (
                            <img
                              key={imageIndex}
                              src={`https://api.youtubemarketingguy.com${service_image.attributes.url}`}
                              alt={service_image.attributes.name}
                            />
                          )
                        )}
                      <h5>
                        {servicesIteam.servicd_text1} <br />
                        {servicesIteam.service_text2}
                      </h5>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>

      {/* ACCELERATE YOUR YOUTUBE GROWTH WITH OUR ADD-ON SERVICES */}

      <div className="container-fluid service-container pt-5 mt-5">
       
       <div className="container">
       <div className="row">
        <div className="col-lg-12">
        <h3>{apiHeadingData.AccelerateGrowthService_Heading}</h3>
        </div>
       </div>
       </div>
        {apiAccelerateData.map((item) => (
          <div key={item.id} className="row service-row pt-4">
            {item.attributes.Accelerate_Growth.map((trustedItem, index) => (
              <React.Fragment key={trustedItem.id}>
                {index % 3 === 0 && (
                  <div className="row service-row pt-4"></div>
                )}
                <div className="col-lg-3">
                  <div className="service-box">
                    {trustedItem.image &&
                      trustedItem.image.data.map((image, imageIndex) => (
                        <img
                          key={imageIndex}
                          src={`https://api.youtubemarketingguy.com${image.attributes.url}`}
                          alt={`Image ${imageIndex + 1}`}
                        />
                      ))}
                    {/* <h5>{trustedItem.team_name}</h5> */}
                    <h5>
                      {trustedItem.text1} <br />
                      {trustedItem.text2}
                    </h5>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>

      {/* LET’S WRITE OUR GROWTH - STORIES TOGETHER IN 2024 */}

      <div className="container contact-container pt-4 pb-5">
      <div className="row justify-content-center">
          <div className="col-lg-10">
        <h3>{apiContactUsData.Contact_Form_Heading}</h3>
        </div>
        </div>
        <div className="row contact-row pt-5">
          <div className="col-lg-6">
            <div className="contact-column">
              <h5>
              {apiContactUsData.Contact_Form_ContentText1} <br /> <b>{apiContactUsData.Contact_Form_ContentText2}</b> <br /> {apiContactUsData.Contact_Form_ContentText3}{" "}
                <br />  {apiContactUsData.Contact_Form_ContentText4}{" "}
                <b>
                {apiContactUsData.Contact_Form_ContentBoldText5} <br /> {apiContactUsData.Contact_Form_ContentBoldText6} <br /> {apiContactUsData.Contact_Form_ContentBoldText7}
                </b>{" "}
                {apiContactUsData.Contact_Form_ContentText8}
              </h5>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="contact-column">
              <form ref={form} onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="name">{apiContactUsData.Contact_Form_NameText}</label>
                  <input
                    type="text"
                    placeholder="Write your name"
                    className={`form-control ${errors.name && "is-invalid"}`}
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    maxLength="100"
                  />
                  
                  {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="email">{apiContactUsData.Contact_Form_EmailText}</label>
                  <input
                    type="email"
                    placeholder="Write your email id"
                    // className={`form-control ${errors.email && "is-invalid"}`}
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    maxLength="100"
                  />
                  {/* {errors.email && <div className="invalid-feedback">{errors.email}</div>} */}
                </div>
                <div className="mb-3 app-input">
                  <label htmlFor="phone">{apiContactUsData.Contact_Form_NumberText}</label>
                  <PhoneInput
                    international
                    defaultCountry="IN"                    
                    className={`form-control ${errors.phone && "is-invalid"}`}
                    value={formData.phone}
                    onChange={(value) => handleChange(value, "phone")} // Pass 'phone' as name
                    inputProps={{
                      id: "phone",
                      name: "phone",
                    }}
                    maxLength="20"
                  />
                  {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                </div>
                <div className="mb-5">
                  <label htmlFor="message">{apiContactUsData.Contact_Form_MessageText}</label>
                  <textarea
                    className={`form-control ${errors.message && "is-invalid"}`}
                    rows="6"
                    placeholder="Write a comment here"
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    maxLength="1000"
                  ></textarea>
                  {errors.message && <div className="invalid-feedback">{errors.message}</div>}
                </div>
                <button type="submit">
                {apiContactUsData.Contact_Form_SubmitText} <i className="bi bi-arrow-right-circle"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="row justify-content-center mt-5">
            <div className="col-lg-4 contact-page-btn">
              <Link to="tel:+919829864624" target="_blank" className="text-contact">
                <button> {apiHeadingData.Service_ContentNumber} </button>
              </Link>
              <button>
                <Link className="service-contact" to="tel:+919829864624" target="_blank">
                  <i className="bi bi-telephone-outbound"></i>{" "}
                  {apiHeadingData.Service_CallText} /{" "}
                </Link>
                <Link
                  className="service-contact"
                  to="https://api.whatsapp.com/send?phone=918655986192" target="_blank"
                >
                  <i class="bi bi-whatsapp me-1"></i>
                  {apiHeadingData.Service_WhatsappText}
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Services;
