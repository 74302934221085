import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
const About = () => {
  const [apiHeadingData, setApiHeadingData] = useState([]);
  const [apiWeBelieveData, setApiWeBelieveData] = useState([]);
  const [apiMeetOurTeamData, setApiMeetOurTeamData] = useState([]);
  const [showAllSport, setShowAllSport] = useState(false);

  const [apiWeAreTeam, setApiWeAreTeam] = useState([]);
  const [apiOurStarter, setApiOurStarter] = useState([]);
  const [apiOurMission, setApiOurMission] = useState([]);
  const [apiOurVision, setApiOurVision] = useState([]);



  const [years, setYears] = useState([]);
  const [selectedYears, setSelectedYears] = useState({});
  const [journeyData, setJourneyData] = useState([]);

  useEffect(() => {
    fetch(
      "https://api.youtubemarketingguy.com/api/about-uses?populate[Our_Journey][populate]=*&pagination[pageSize]=100"
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (
          responseData &&
          responseData.data &&
          Array.isArray(responseData.data)
        ) {
          const data = responseData.data;
          const yearsFromApi = data.map((item) =>
            item.attributes.Our_Journey.map((journey) => journey.Year)
          );
          const flattenedYears = yearsFromApi.flat();
          const uniqueYears = Array.from(new Set(flattenedYears));
          setYears(uniqueYears);
          setJourneyData(data);
        } else {
          throw new Error("Invalid API response format");
        }
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, []);

  const handleYearChange = (event, itemId) => {
    setSelectedYears((prevState) => ({
      ...prevState,
      [itemId]: event.target.value,
    }));
  };

  // Create a sorted copy of years in descending order
  const sortedYears = [...years].sort((a, b) => b - a);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/about-uses?populate[We_Are_Team][populate]=*"
        );
        const data = await response.json();
        setApiWeAreTeam(data.data[0]?.attributes.We_Are_Team[0] || {});
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/about-uses?populate[Our_Vision][populate]=*"
        );
        const data = await response.json();
        setApiOurVision(data.data[0]?.attributes.Our_Vision[0] || {});
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/about-uses?populate[Our_Starter][populate]=*"
        );
        const data = await response.json();
        setApiOurStarter(data.data[0]?.attributes.Our_Starter[0] || {});
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSportSeeMoreClick = () => {
    setShowAllSport(prevShowAllSport => !prevShowAllSport);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/about-uses?populate[Meet_Our_Team][populate]=*&pagination[pageSize]=1000"
        );
        const data = await response.json();
        setApiMeetOurTeamData(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/about-uses?populate[Our_Mission][populate]=*"
        );
        const data = await response.json();
        setApiOurMission(data.data[0]?.attributes.Our_Mission[0] || {});
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/about-uses?populate[We_Believe][populate]=*"
        );
        const data = await response.json();
        setApiWeBelieveData(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/about-uses?populate"
        );
        const data = await response.json();
        setApiHeadingData(data.data[0]?.attributes || {});
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Navbar />

      <div className="container">
        <div className="row justify-content-center pt-5">
          <div className="col-lg-12">
            <div className="story-heading">
              <h2>
                {" "}
                <span>WE</span> ARE{" "}
              </h2>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="story-heading">
              <h2>
                {" "}
                <span>CO</span>de, <span>CO</span>ntent, <span>MA</span>
                rketing{" "}
              </h2>
            </div>
            <div className="team-img pt-5">
              {apiWeAreTeam.image && (
                <img
                  src={`https://api.youtubemarketingguy.com${apiWeAreTeam.image.data[0].attributes.url}`}
                  alt="Image not found"
                />
              )}
            </div>
          </div>
          <div className="col-lg-10 pt-4">
            <div className="team-content">
              <h3>{apiWeAreTeam.text}</h3>
            </div>
          </div>
        </div>
      </div>

      {/* We Believe */}

      <div className="container pt-5">
        <div className="row">
          <div className="col-lg-8 story-feat-heading">
            <h2>
              {" "}
              {apiHeadingData.Believe_text1}{" "}
              <span> {apiHeadingData.Believe_text2}</span>{" "}
              {apiHeadingData.Believe_text3}{" "}
            </h2>
          </div>
        </div>
        {apiWeBelieveData.map((item) => (
          <div key={item.id} className="row service-row pt-5">
            {item.attributes.We_Believe.map((belief) => (
              <div key={belief.id} className="col-lg-3">
                <div className="story-features">
                  {belief.image &&
                    belief.image.data.map((image) => (
                      <img
                        key={image.id}
                        src={`https://api.youtubemarketingguy.com${image.attributes.url}`}
                        alt={image.attributes.name}
                      />
                    ))}
                  <h5>{belief.text}</h5>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="container pt-5 pb-5">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="mid-text">
              <h4>
                <i className="fa-solid fa-quote-left left-ico"></i>{" "}
                {apiHeadingData.Best_idea}{" "}
                <i className="fa-solid fa-quote-right right-ico"></i>
              </h4>
            </div>
          </div>
        </div>
      </div>

      {/* MEET OUR STARTER */}

      <div className="container">
        <div className="story-feat-heading">
          <h2>
            {apiHeadingData.Starter_text1}{" "}
            <span>{apiHeadingData.Starter_text2} </span>{" "}
          </h2>
        </div>


        <div className="row founder-heading align-items-center">
          <div className="col-lg-8">
            <h5>{apiOurStarter.text1}</h5>
            <p>{apiOurStarter.text2}</p>
          </div>
          <div className="col-lg-4">
            <div className="founder-img">
              {apiOurStarter.image && (
                <img
                  src={`https://api.youtubemarketingguy.com${apiOurStarter.image.data[0].attributes.url}`}
                  alt="Image not found"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* MEET OUR TEAM */}
      <div className="container mb-5 pt-5">
        <div className="story-feat-heading">
          <h2>
            {apiHeadingData.Teams_text1}{" "}
            <span>{apiHeadingData.Teams_text2} </span>{" "}
          </h2>
        </div>
        <div className="row meet-row">
          {apiMeetOurTeamData.map((team) =>
            team.attributes.Meet_Our_Team.slice(
              0,
              showAllSport ? undefined : 6
            ).map((member) => (
              <div key={member.id} className="col-lg-6">
                <div className="meet-team">
                  {member.image &&
                    member.image.data &&
                    member.image.data.map((image, imageIndex) => (
                      <img
                        key={imageIndex}
                        src={`https://api.youtubemarketingguy.com${image.attributes.url}`}
                        alt={image.attributes.name}
                      />
                    ))}
                  <div className="info-box">
                    <div className="team-name">
                      <h4>{member.text}</h4>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        <div className="row justify-content-center pt-4">
          <div className="col-lg-4">
            <div className="see-box" onClick={handleSportSeeMoreClick}>
              {/* <h4>
                see more <i className="bi bi-arrow-down-circle-fill"></i>
              </h4> */}
              <h4>
                {showAllSport ? 'See less' : 'See more'}{' '}
                <i className={`bi bi-arrow-${showAllSport ? 'up' : 'down'}-circle-fill`}></i>
              </h4>
            </div>
          </div>
        </div>
      </div>

      {/* OUR MISSION */}

      <div className="container-fluid mission-container">
        <div className="container mission-container mt-5 pt-4">
          <div className="story-feat-heading">
            <h2 className="abt-mission">
              {" "}
              <span>{apiHeadingData.Misssion_text} </span>{" "}
            </h2>
          </div>

          <div className="row align-items-center pt-2">
            <div className="col-lg-6">
              <div className="mission-content">
                <h6>{apiOurMission.text1}</h6>
                <h5 className="pt-3">{apiOurMission.text2}</h5>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mission-img">
                {apiOurMission.image && (
                  <img
                    src={`https://api.youtubemarketingguy.com${apiOurMission.image.data[0].attributes.url}`}
                    alt="Image not found"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Our Vision */}

      <div className="container-fluid mission-container">
        <div className="container mission-container mt-5 pt-4">
          <div className="story-feat-heading">
            <h2 className="abt-mission">
              {apiHeadingData.Vision_text1}{" "}
              <span>{apiHeadingData.Vision_text2} </span>{" "}
            </h2>
          </div>
          {/* <h4>{apiOurVision.heading}</h4> */}

          <div className="row align-items-center pt-2">
            <div className="col-lg-6">
              <div className="mission-img">
                {apiOurVision.image && (
                  <img
                    src={`https://api.youtubemarketingguy.com${apiOurVision.image.data[0].attributes.url}`}
                    alt="Image not found"
                  />
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mission-content">
                <h2>{apiOurVision.text}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* READ OUR JOURNEY */}

      <div className="container-fluid mt-5 mb-5 journey-box">
        <div className="container">
          {journeyData.map((item) => (
            <div className="row" key={item.id}>
              <div className="col-lg-6 story-feat-heading">
                <h2>
                  {apiHeadingData.Journey_text1}{" "}
                  <span>{apiHeadingData.Journey_text2}</span>
                </h2>
              </div>
              <div className="col-lg-2 pt-4 mt-2">
                <div className="year-box">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={selectedYears[item.id] || ""}
                    onChange={(e) => handleYearChange(e, item.id)}
                  >
                    {sortedYears.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {item.attributes.Our_Journey.filter(
                (belief) =>
                  belief.Year === (selectedYears[item.id] || sortedYears[0])
              ).map((belief) => (
                <div key={belief.id} className="row pt-4">
                  <div className="journey-list col-lg-9">
                    <ul>
                      {[...Array(10)].map((_, index) => (
                        belief[`text${index + 1}`] && (
                          <li key={index}>
                            {`${belief[`text${index + 1}`]}`}

                          </li>
                        )
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      {/* PLEASE ALSO VISIT OUR STUDIO */}

      <div className="container pt-5 pb-5">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="studio-heading">
              <h2>
                {apiHeadingData.Studio_text1}{" "}
                <span>{apiHeadingData.Studio_text2}</span> <br />
                {apiHeadingData.Product_text1}{" "}
                <span>{apiHeadingData.Product_text2}</span>{" "}
              </h2>

              <img src="images/love.png" alt="" className="pt-2" />

              <div className="help-btn pt-5">
                <button>
                  {apiHeadingData.Explore_Service_text}{" "}
                  <i className="bi bi-arrow-right-circle"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default About;
