import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
  const location = useLocation();

  return (
    <>
      <header className='sticky-top'>
        <div className="container-fluid">
          <div className="row header-row">
            <div className='col-3 head-box'>
              <li> <Link to="#" className={location.pathname === '/ourstudio' ? 'active'  : 'head-link'}>OUR STUDIO</Link> </li>
              <li> <Link  to="#" className={location.pathname === '/products' ? 'active' : 'head-link'}>PRODUCTS</Link> </li>
              <li> <Link  to="#" className={location.pathname === '/academy' ? 'active' : 'head-link'}>ACADEMY</Link> </li>
              <li> <Link to="#" className={location.pathname === '/login' ? 'active' : 'head-link'}>LOGIN</Link> </li>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <Link className="navbar-brand" to="/">
              {/* <img src={`images/new-logo.png`} alt="" /> */}
              
          <img src={`${process.env.PUBLIC_URL}/images/new-logo.png`} alt="" />
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto">
                <li className="nav-item">
                  <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} to="/">Home</Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link ${location.pathname === '/ourwork' ? 'active' : ''}`} to="/ourwork">Our Work</Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link ${location.pathname === '/service' ? 'active' : ''}`} to="/service">Services</Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link ${location.pathname === '/about' ? 'active' : ''}`} to="/about">ABOUT US</Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link ${location.pathname === '/career' ? 'active' : ''}`} to="/career">WE’RE HIRING</Link>

                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/blog">BLOG</Link>
                </li>
              </ul>
              <form className="d-flex">
                <Link to="/contact"><button className="join-button" type="submit">Contact Us</button></Link>
              </form>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Navbar;
