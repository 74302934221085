import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'; // Import useParams hook
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Singleservice = () => {
  const [apiServicesImagesData, setApiServicesImagesData] =
    useState([]);
  const [apiHeadingData, setApiHeadingData] = useState([]);
  const [apiTrustedByTheBest, setApiTrustedByTheBest] = useState([]);

 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/services?populate[Service_TrustedByTheBest][populate]=*"
        );
        const data = await response.json();
        setApiTrustedByTheBest(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/services?populate[populate]=*"
        );
        const data = await response.json();
        setApiHeadingData( data.data[0]?.attributes || {});
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.youtubemarketingguy.com/api/services?populate[SingleServiceBanner][populate]=*"
        );
        const data = await response.json();
        // Extract the SingleServiceBanner data from the API response
        const singleServiceBannerData = data.data[0]?.attributes.SingleServiceBanner[0];
        setApiServicesImagesData(singleServiceBannerData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


 
  const { serviceId } = useParams();
  const [serviceDetails, setServiceDetails] = useState([]);
  
  useEffect(() => {
    const fetchServiceDetails = async () => {
      try {
        const response = await fetch(`https://api.youtubemarketingguy.com/api/services?populate[0]=Service_Component&populate[1]=Service_Component.Service_Sub_Content&populate[2]=Service_Component.Service_Sub_Content.Sub_image.Sub_image`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        const serviceComponent = data.data[0].attributes.Service_Component.find(service => service.id == serviceId);
        setServiceDetails([serviceComponent]); // Wrap the single object in an array
      } catch (error) {
        console.error("Error fetching service details:", error);
      }
    };
  
    fetchServiceDetails();
  }, [serviceId]);


  const ContentWithImageRight = ({ subContent }) => (
    <div className="row pt-5">
      <div className="col-lg-6">
        <div className="single-content">
          <h5 className="pt-5">{subContent.Sub_text1}</h5>
          <p className="mt-5 pb-5">{subContent.Sub_text2}</p>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="single-img">
          {subContent.Sub_image.data.length > 0 && (
            <img src={`https://api.youtubemarketingguy.com${subContent.Sub_image.data[0].attributes.url}`} alt="" />
          )}
        </div>
      </div>
    </div>
  );

  const ContentWithImageLeft = ({ subContent }) => (
    <div className="container pt-4">
    <div className="row">
      <div className="col-lg-6">
        <div className="single-img">
          {subContent.Sub_image.data.length > 0 && (
            <img src={`https://api.youtubemarketingguy.com${subContent.Sub_image.data[0].attributes.url}`} alt="" />
          )}
        </div>
      </div>
          <div className="col-lg-6">
        <div className="single-content">
          <h5 className="pt-5">{subContent.Sub_text1}</h5>
          <p className="mt-5 pb-5">{subContent.Sub_text2}</p>
        </div>
      </div>
    </div>
    </div>
  );

  return (
    <>
      <Navbar />

      <div className="container-fluid main-service-banner">
      
        <div className="row service-banner">
          <div className="col-lg-7 pt-5 pb-5">
            <h3>{apiServicesImagesData.SingleServiceBanner_Text1}</h3>
            <h3>{apiServicesImagesData.SingleServiceBanner_Text2}</h3>
            <h3>{apiServicesImagesData.SingleServiceBanner_Text3}</h3>
          </div>
          <div className="col-lg-5 right-ser-column pb-5">
            <div className="img-side-ban">
            {apiServicesImagesData.SingleServiceBanner_Image && ( 
              <img
                src={`https://api.youtubemarketingguy.com${apiServicesImagesData.SingleServiceBanner_Image.data[0].attributes.url}`}
                alt=""
              />
                )}
            </div>
          </div>
        </div>
    
    </div>

      {/* TRUSTED_BY_THE_BEST */}

      <div className="container pt-5 pb-4">
        <div className="row trust-row">
          <h5>{apiHeadingData.TrustedByTheBest_Heading}</h5>
          <div className="marquee mt-5">
            <div className="marquee-content">
              {apiTrustedByTheBest.map((item) =>
                item.attributes.Service_TrustedByTheBest.map(
                  (trustedItem) =>
                    trustedItem.Images &&
                  trustedItem.Images.data &&
                    trustedItem.Images.data.map(
                      (image, imageIndex) => (
                        <div className="marquee-item" key={imageIndex}>
                          <img
                            src={`https://api.youtubemarketingguy.com${image.attributes.url}`}
                            alt={`Image ${imageIndex + 1}`}
                          />
                        </div>
                      )
                    )
                )
              )}
            </div>
          </div>
        </div>
      </div>

      
  

  {/* OUR SERVICES */}

<div className="container service-container pt-5">
      <h3>{apiHeadingData.OurService_Heading}</h3>
      {serviceDetails.map((serviceItem, index) => (
        <div key={index}>
          {serviceItem.Service_Sub_Content.map((subContent, subIndex) => (
            <div key={subIndex}>
              {subIndex % 2 === 0 ? (
               <ContentWithImageRight subContent={subContent} />
              ) : (
               
                <ContentWithImageLeft subContent={subContent} />
              )}
            </div>
          ))}
        </div>
      ))}
    </div>

      <div className="container contact-container pt-4 pb-5">
        <div className="row justify-content-center mt-5">
          <div className="col-lg-4 contact-page-btn">
            <Link to="tel:+919829864624" target="_blank" className="text-contact" >
              <button> {apiHeadingData.Service_ContentNumber} </button>
            </Link>
            <button>
              <Link
                className="service-contact"
                to="tel:+919829864624"
                target="_blank"
              >
                <i className="bi bi-telephone-outbound"></i>{" "}
                {apiHeadingData.Service_CallText} /{" "}
              </Link>
              <Link
                className="service-contact"
                to="https://api.whatsapp.com/send?phone=918655986192"
                target="_blank"
              >
                <i class="bi bi-whatsapp me-1"></i>
                {apiHeadingData.Service_WhatsappText}
              </Link>
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Singleservice;
